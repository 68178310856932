import instance from './instance';

const STATION_BASE_URL = 'station';
const STATIONS_RACK_BASE_URL = 'stations-rack';

export const getOneStationRack = (stationsRackId) => {
  return instance.get(`${STATIONS_RACK_BASE_URL}/${stationsRackId}`);
};

const deleteStationFromRack = (stationId) => {
  return instance.put(`${STATION_BASE_URL}/${stationId}`, { stationsRackId: null });
};

export default deleteStationFromRack;
