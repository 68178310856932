import React from 'react';
import PropTypes from 'prop-types';
import {
  CardActions,
  CreateButton,
  RefreshButton,
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  EditButton,
  SelectInput,
  SelectField,
  NumberInput,
  NumberField,
  ReferenceField,
  ReferenceInput,
  Pagination,
  DateField,
} from 'react-admin';

import PowerbankActionButtons from './PowerbankActions';

const statusList = [
  { id: 'NOOP', name: 'NOOP' },
  { id: 'STATION', name: 'STATION' },
  { id: 'USER', name: 'USER' },
  { id: 'STOLEN', name: 'STOLEN' },
  { id: 'BROKEN', name: 'BROKEN' },
];

const PowerbankActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </CardActions>
);

PowerbankActions.propTypes = {
  basePath: PropTypes.string,
};

const PbsPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500]} {...props} />
);

export const PowerbankList = (props) => (
  <List
    {...props}
    sort={{ field: 'id', order: 'ASC' }}
    pagination={<PbsPagination />}
    actions={<PowerbankActions />}
  >
    <Datagrid>
      <TextField source="id" />
      <DateField source="createdAt" showTime locales="en-GB" />
      <DateField source="updatedAt" showTime locales="en-GB" />
      <TextField source="hardwareId" label="Hardware ID" />
      <ReferenceField label="Station" source="stationId" reference="station" allowEmpty>
        <TextField source="hardwareId" />
      </ReferenceField>
      <SelectField source="status" choices={statusList} />
      <NumberField source="chargeLevel" label="Charge level" />
      <NumberField source="timesUsed" label="Times used" />
      <EditButton />
    </Datagrid>
  </List>
);

export const PowerbankCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="hardwareId" label="Hardware ID" />
      <SelectInput source="status" label="Status" choices={statusList} />
      <NumberInput source="chargeLevel" label="Charge level" step={1} />
      <ReferenceInput label="Station ID" source="stationId" reference="station">
        <SelectInput optionText="id" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

const PowerbankTitle = ({ record }) => (
  <span>{record ? `Powerbank ${record.hardwareId}` : ''}</span>
);

PowerbankTitle.propTypes = {
  record: PropTypes.shape({
    hardwareId: PropTypes.string,
  }),
};

export const PowerbankEdit = (props) => (
  <div>
    <Edit title={<PowerbankTitle />} actions={<PowerbankActionButtons />} {...props}>
      <SimpleForm>
        <TextInput source="hardwareId" label="Hardware ID" />
        <SelectInput source="status" label="Status" choices={statusList} />
        <NumberInput source="chargeLevel" label="Charge level" />
        <ReferenceInput label="Station ID" source="stationId" reference="station">
          <SelectInput optionText="id" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  </div>
);
