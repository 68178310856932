import React, { Component } from 'react';
import XLSX from 'xlsx';
import { make_cols } from './utils';
import { SheetJSFT } from './types';

class ExcelReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      data: [],
      cols: []
    }
    this.handleFile = this.handleFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.setState({ file: files[0] });
  };

  handleFile() {
    /* Boilerplate to set up FileReader */
    if (!this.state.file.name) {
      return;
    }
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      /* Update state */
      this.setState({ data, cols: make_cols(ws['!ref']) }, () => {
        this.props.onParse(data.map(stationRow => Object.values(stationRow)[0]));
      });

    };

    if (rABS) {
      reader.readAsBinaryString(this.state.file);
    } else {
      reader.readAsArrayBuffer(this.state.file);
    };
  }

  render() {
    return (
      <div style={{ padding: '20px 0 0' }}>
        <label htmlFor="file"><b>Upload excel with stations Id</b></label>
        <input type="file" style={{ display: 'block', marginTop: '10px' }} id="file" accept={SheetJSFT} onChange={this.handleChange} />
        {this.state.file.name && <input type='button'
          value="Send stations"
          style={{
            backgroundColor: "#2196f3",
            color: "#fff",
            padding: '5px',
            fontSize: "16px",
            border: "1px solid transparent",
            margin: '10px 0',
            borderRadius: '3px'
          }}
          onClick={this.handleFile} />}
      </div>

    )
  }
}

export default ExcelReader;