import PropTypes, { number } from 'prop-types';
import React from 'react';
import {
  CardActions,
  Create,
  CreateButton,
  Datagrid,
  Edit,
  EditButton,
  List,
  RefreshButton,
  SimpleForm,
  TextField,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  ReferenceField,
  SelectInput,
} from 'react-admin';

const VoucherTemplateActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </CardActions>
);

VoucherTemplateActions.propTypes = {
  basePath: PropTypes.string,
};

export const VoucherTemplateList = (props) => {
  return (
    <List {...props} actions={<VoucherTemplateActions />} sort={{ field: 'id', order: 'ASC' }}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" label="name" />
        <TextField source="redeemBannerUrl" label="redeemBannerUrl" />
        <TextField source="codeBannerUrl" label="codeBannerUrl" />
        <ReferenceField source="campaignId" reference="campaign">
          <TextField source="title" />
        </ReferenceField>
        <TextField
          source="conditions"
          parse={(v) => JSON.parse(v)}
          format={(v) => JSON.stringify(v)}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const VoucherTemplateCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" label="name" />
        <TextInput source="redeemBannerUrl" label="redeemBannerUrl" />
        <TextInput source="codeBannerUrl" label="codeBannerUrl" />
        <ReferenceInput label="Campaign" source="campaign.id" reference="campaign">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <ArrayInput source="conditions">
          <SimpleFormIterator>
            <TextInput source="city" />
            <TextInput source="country" />
            <ReferenceInput label="Partner" source="startPartner" reference="partner">
              <SelectInput optionText="title" />
            </ReferenceInput>
            <ReferenceInput label="Partner" source="endPartner" reference="partner">
              <SelectInput optionText="title" />
            </ReferenceInput>
            <TextInput source="minimumDuration" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export const VoucherTemplateEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" label="name" />
        <TextInput source="redeemBannerUrl" label="redeemBannerUrl" />
        <TextInput source="codeBannerUrl" label="codeBannerUrl" />
        <ReferenceInput label="Campaign" source="campaign.id" reference="campaign">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <ArrayInput source="conditions">
          <SimpleFormIterator>
            <TextInput source="city" />
            <TextInput source="country" />
            <ReferenceInput label="Start Partner" source="startPartner" reference="partner">
              <SelectInput optionText="name" source="id" />
            </ReferenceInput>
            <ReferenceInput label="End Partner" source="endPartner" reference="partner">
              <SelectInput optionText="name" source="id" />
            </ReferenceInput>
            <TextInput source="minimumDuration" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

VoucherTemplateEdit.propTypes = {
  id: number,
};
