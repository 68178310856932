/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  FormDataConsumer,
  FormTab,
  FunctionField,
  List,
  ReferenceInput,
  ReferenceField,
  ReferenceManyField,
  SelectField,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin';

import { Button } from '@material-ui/core';
import moment from 'moment';
import deleteStationFromRack, { getOneStationRack } from '../../api/stations-rack';

export const StationsRackList = (props) => (
  <List {...props} sort={{ field: 'id', order: 'ASC' }}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="hardwareId" label="Hardware ID" />
      <ReferenceField label="Location" source="locationId" reference="location">
        <TextField source="internalName" />
      </ReferenceField>
      <SelectField
        source="status"
        choices={[
          { id: 'ON', name: 'ON' },
          { id: 'OFF', name: 'OFF' },
        ]}
      />
      <DateField source="updatedAt" showTime locales="en-GB" />
      <EditButton />
    </Datagrid>
  </List>
);

export const StationsRackCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="hardwareId" label="Hardware ID" />
        <ReferenceInput label="Location" reference="location" source="locationId">
          <AutocompleteInput optionText="internalName" optionValue="id" />
        </ReferenceInput>
        <SelectInput
          source="status"
          label="Status"
          choices={[
            {
              id: 'ON',
              name: 'ON',
            },
            {
              id: 'OFF',
              name: 'OFF',
            },
          ]}
        />
      </SimpleForm>
    </Create>
  );
};

export const StationsRackEdit = ({ ...props }) => {
  const [refreshIndicator, setRefreshIndicator] = useState(false);

  const { id: stationsRackId } = props;

  const handleDeleteStationClick = async (stationId) => {
    setRefreshIndicator(true);

    await deleteStationFromRack(stationId);
    await getOneStationRack(stationsRackId);

    setRefreshIndicator(false);
  };

  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="stations rack">
          <TextInput source="hardwareId" />
          <SelectInput
            source="status"
            choices={[
              { id: 'ON', name: 'ON' },
              { id: 'OFF', name: 'OFF' },
            ]}
          />
          <ReferenceInput label="Location" reference="location" source="locationId">
            <AutocompleteInput optionText="internalName" optionValue="id" />
          </ReferenceInput>
        </FormTab>
        <FormTab label="attached stations">
          <ReferenceManyField
            label="Stations"
            reference="station"
            target="stationsRackId"
            key={refreshIndicator}
          >
            <Datagrid>
              <TextField source="id" />
              <ReferenceField label="Hardware ID" source="id" reference="station">
                <TextField source="hardwareId" />
              </ReferenceField>

              <TextField source="status" />
              <FormDataConsumer>
                {({ record }) => {
                  return (
                    <>
                      <Button
                        variant="contained"
                        onClick={() => handleDeleteStationClick(record.id)}
                        color="secondary"
                        style={{ marginRight: '15px' }}
                      >
                        Delete from rack
                      </Button>
                    </>
                  );
                }}
              </FormDataConsumer>
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="leases">
          <ReferenceManyField label="Leases" reference="lease" target="sourceStationsRackId">
            <Datagrid>
              <FunctionField
                label="Created At"
                render={(record) => moment(record.createdAt).format('HH:mm DD/MM/YYYY')}
              />
              <TextField source="sourceStationsRackId" />
              <TextField source="returnStationId" />
              <TextField source="sourceStationId" />
              <TextField source="userId" />
              <TextField source="status" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
