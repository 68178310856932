import instance from './instance';
import config from '../config';

const LEASE_BASE_URL = config.routes.leases;

const getExportData = (filters) => {
  return instance.get(`${LEASE_BASE_URL}/export/?filters=${JSON.stringify(filters)}`);
};

export default getExportData;
