import React from 'react';
import { NumberInput } from 'react-admin';

export const PriceInput = (props) => (
  <NumberInput
    parse={(v) => v * 100}
    format={(v) => v ? v / 100 : null}
    {...props}
  />
);
