import PropTypes, { number } from 'prop-types';
import React from 'react';
import {
  BooleanField,
  BooleanInput,
  CardActions,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  List,
  RefreshButton,
  SimpleForm,
  TextField,
  TextInput,
  NumberField,
  NumberInput,
  ReferenceInput,
  AutocompleteInput, required,
} from 'react-admin';

const CampaignActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </CardActions>
);

CampaignActions.propTypes = {
  basePath: PropTypes.string,
};

export const CampaignsList = (props) => {
  return (
    <List {...props} actions={<CampaignActions />} sort={{ field: 'id', order: 'ASC' }}>
      <Datagrid>
        <TextField source="id" />
        <NumberField source="rank" />
        <TextField source="title" label="title" />
        <DateField source="startDate" locales="en-GB" />
        <DateField source="endDate" locales="en-GB" />
        <BooleanField source="isActive" />
        <BooleanField source="isGlobal" />
        <TextField
          source="conditions"
          parse={(v) => JSON.parse(v)}
          format={(v) => JSON.stringify(v)}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const CampaignsCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <NumberInput source="rank" validate={[required()]} helperText="higher number -> higher position"/>
        <TextInput source="title" label="title" />
        <TextInput source="campaignUrl" label="campaignUrl" />
        <TextInput source="redeemableCampaignUrl" label="redeemableCampaignUrl" />
        <TextInput source="promoCampaignUrl" label="promoCampaignUrl" />
        <TextInput source="detailsPage" label="detailsPage" />
        <DateInput source="startDate" locales="en-GB" />
        <DateInput source="endDate" locales="en-GB" />
        <BooleanInput source="isActive" />
        <BooleanInput source="isGlobal" />
        <ReferenceInput label="Partner" source="partner.id" reference="partner" alwaysOn perPage={50}>
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <TextInput
          source="conditions"
          parse={(v) => JSON.parse(v)}
          format={(v) => JSON.stringify(v)}
        />
      </SimpleForm>
    </Create>
  );
};

export const CampaignsEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <NumberInput source="rank" validate={[required()]} helperText="higher number -> higher position"/>
        <TextInput source="title" label="title" />
        <TextInput source="campaignUrl" label="campaignUrl" />
        <TextInput source="redeemableCampaignUrl" label="redeemableCampaignUrl" />
        <TextInput source="promoCampaignUrl" label="promoCampaignUrl" />
        <TextInput source="detailsPage" label="detailsPage" />
        <DateInput source="startDate" locales="en-GB" />
        <DateInput source="endDate" locales="en-GB" />
        <BooleanInput source="isActive" />
        <BooleanInput source="isGlobal" />
        <ReferenceInput label="Partner" source="partner.id" reference="partner" alwaysOn perPage={50}>
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <TextInput
          source="conditions"
          parse={(v) => JSON.parse(v)}
          format={(v) => JSON.stringify(v)}
        />
      </SimpleForm>
    </Edit>
  );
};

CampaignsEdit.propTypes = {
  id: number,
};
