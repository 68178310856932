import React, { useState } from 'react';
import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  EditButton,
  NumberInput,
  DateField,
  BooleanInput,
  FunctionField,
  SelectInput,
  SelectArrayInput,
  ReferenceInput,
  ReferenceArrayInput,
  FormDataConsumer,
  AutocompleteInput,
  required,
} from 'react-admin';
import { DateInput, DateTimeInput } from 'react-admin-date-inputs';
import './style.css';
import {
  PromocodeTypeBalance, PromocodeTypeDayPass,
  PromocodeTypeLease,
  PromocodeTypeSubscription,
} from '../../config/constants';

export const PromoCodeList = (props) => (
  <List {...props} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid>
      <TextField source="code" label="Code" />
      <TextField source="timesUsed" label="Times Used" />
      <FunctionField label="Money Value" render={(record) => `${record.value / 100} SEK`} />
      <FunctionField label="Is enabled" render={(record) => (record.isEnabled ? 'ON' : 'OFF')} />
      <TextField source="type" label="Type" />
      <DateField source="startDate" label="Start date" />
      <DateField source="endDate" label="End date" />
      <EditButton />
    </Datagrid>
  </List>
);

export const PromoCodeCreate = (props) => {
  const [promoType, setPromoType] = useState(null);

  return (
    <Create {...props}>
      <SimpleForm>
        <FormDataConsumer>{({ formData }) => setPromoType(formData.type)}</FormDataConsumer>

        <BooleanInput label="Is enabled" source="isEnabled" />
        <TextInput source="code" validate={[required()]} label="Code" />

        <SelectInput
          source="type"
          validate={[required()]}
          choices={[
            { id: PromocodeTypeLease, name: PromocodeTypeLease },
            { id: PromocodeTypeBalance, name: PromocodeTypeBalance },
            { id: PromocodeTypeSubscription, name: PromocodeTypeSubscription },
            { id: PromocodeTypeDayPass, name: PromocodeTypeDayPass },
          ]}
        />

        {promoType && promoType !== PromocodeTypeSubscription && promoType !== PromocodeTypeDayPass && (
          <>
            <NumberInput
              source="value"
              validate={[required()]}
              label="Money Value"
              style={{
                minWidth: 256,
              }}
            />

            <DateInput
              source="startDate"
              label="Start Date"
              options={{ format: 'dd/MM/YYYY' }}
              validate={[required()]}
              className="custom-input-width"
            />

            <DateInput
              source="endDate"
              label="End Date"
              options={{ format: 'dd/MM/YYYY' }}
              validate={[required()]}
              className="custom-input-width"
            />

            <ReferenceArrayInput
              label="Post"
              source="locationIds"
              reference="location"
              perPage={5000}
              className="custom-input-width override-flex-direction"
            >
              <SelectArrayInput optionText="internalName" optionValue="id" />
            </ReferenceArrayInput>

            <NumberInput
              source="timesToUse"
              label="Times to be used"
              className="custom-input-width override-flex-direction"
            />
          </>
        )}

        {promoType && promoType === PromocodeTypeDayPass && (
          <>
            <DateTimeInput
              source="startDate"
              label="Start Date"
              options={{ format: 'dd/MM/YYYY hh:mm' }}
              validate={[required()]}
              className="custom-input-width"
            />

            <DateTimeInput
              source="endDate"
              label="End Date"
              options={{ format: 'dd/MM/YYYY hh:mm' }}
              validate={[required()]}
              className="custom-input-width"
            />
          </>
        )}

        {promoType && promoType === PromocodeTypeSubscription && (
          <ReferenceInput
            label="Subscription Config"
            source="subscriptionConfigId"
            reference="subscription-config"
            perPage={500}
            validate={[required()]}
          >
            <AutocompleteInput optionText="title" optionValue="id" />
          </ReferenceInput>
        )}

        {promoType && promoType === PromocodeTypeDayPass && (
          <ReferenceInput
            label="DayPass Config"
            source="dayPassConfigId"
            reference="daypass-config"
            perPage={500}
            validate={[required()]}
          >
            <AutocompleteInput optionText="title" optionValue="id" />
          </ReferenceInput>
        )}
      </SimpleForm>
    </Create>
  );
};

export const PromoCodeEdit = (props) => {
  const [promoType, setPromoType] = useState(null);

  return (
    <Edit {...props}>
      <SimpleForm>
        <FormDataConsumer>{({ formData }) => setPromoType(formData.type)}</FormDataConsumer>

        <BooleanInput label="Is enabled" source="isEnabled" />
        <TextInput source="code" validate={[required()]} label="Code" />

        <SelectInput
          source="type"
          choices={[
            { id: PromocodeTypeLease, name: PromocodeTypeLease },
            { id: PromocodeTypeBalance, name: PromocodeTypeBalance },
            { id: PromocodeTypeSubscription, name: PromocodeTypeSubscription },
            { id: PromocodeTypeDayPass, name: PromocodeTypeDayPass },
          ]}
        />
        {promoType && promoType !== PromocodeTypeSubscription && promoType !== PromocodeTypeDayPass && (
          <>
            <TextField source="dynamicLink" label="Link" />
            <NumberInput
              source="value"
              validate={[required()]}
              label="Money Value"
              style={{
                minWidth: 256,
              }}
            />

            <DateInput
              source="startDate"
              label="Start Date"
              options={{ format: 'dd/MM/YYYY' }}
              validate={[required()]}
              className="custom-input-width"
            />

            <DateInput
              source="endDate"
              label="End Date"
              options={{ format: 'dd/MM/YYYY' }}
              validate={[required()]}
              className="custom-input-width"
            />

            <ReferenceArrayInput
              label="Post"
              source="locationIds"
              reference="location"
              perPage={5000}
              className="custom-input-width override-flex-direction"
            >
              <SelectArrayInput optionText="internalName" optionValue="id" />
            </ReferenceArrayInput>

            <NumberInput
              source="timesToUse"
              label="Times to be used"
              className="custom-input-width override-flex-direction"
            />
          </>
        )}

        {promoType && promoType === PromocodeTypeDayPass && (
          <>
            <DateTimeInput
              source="startDate"
              label="Start Date"
              options={{ format: 'dd/MM/YYYY hh:mm' }}
              validate={[required()]}
              className="custom-input-width"
            />

            <DateTimeInput
              source="endDate"
              label="End Date"
              options={{ format: 'dd/MM/YYYY hh:mm' }}
              validate={[required()]}
              className="custom-input-width"
            />
          </>
        )}

        {promoType && promoType === PromocodeTypeSubscription && (
          <ReferenceInput
            label="Subscription Config"
            source="subscriptionConfigId"
            reference="subscription-config"
            perPage={500}
            validate={[required()]}
          >
            <AutocompleteInput optionText="title" optionValue="id" />
          </ReferenceInput>
        )}

        {promoType && promoType === PromocodeTypeDayPass && (
          <ReferenceInput
            label="DayPass Config"
            source="dayPassConfigId"
            reference="daypass-config"
            perPage={500}
            validate={[required()]}
          >
            <AutocompleteInput optionText="title" optionValue="id" />
          </ReferenceInput>
        )}
        <TextField source="timesUsed" label="Times Used" />
      </SimpleForm>
    </Edit>
  );
};
