import instance from './instance';

const PARTNER_BASE_URL = '/partner';

export function deleteLocationFromPartner(partnerId, locationId) {
  return instance.delete(`${PARTNER_BASE_URL}/${partnerId}/${locationId}`);
}

export function getPartnersBalance(partnerId) {
  return instance.get(`${PARTNER_BASE_URL}/${partnerId}/balance`);
}

export function withdrawMoney(partnerId) {
  return instance.post(`${PARTNER_BASE_URL}/${partnerId}/withdraw`);
}
