import {
  AccountBalanceWallet,
  Code,
  EvStation,
  FlashOn,
  Games,
  Language,
  LocalOffer,
  LocationCity,
  Notifications,
  Person,
  Power,
  Receipt,
  Vibration,
  Redeem,
  LocalActivity,
  AttachMoney,
  Subscriptions,
  ViewModule,
} from '@material-ui/icons';
import React from 'react';
import jsonServerProvider from 'ra-data-json-server';
import { Admin, fetchUtils, Resource } from 'react-admin';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MenuList from './Menu';
import config from './config';
import AuthClient from './config/auth';
import UserList from './components/user';
import UserEdit from './components/user/user-edit';
import AddBalance from './components/balance';
import UserShow from './components/user/user-show';
import LoginPage from './components/login/LoginPage';
import Dashboard from './components/dashboard/Dashboard';
import DiscountsList from './components/discounts/Discounts';
import LeasesEventsList from './components/lease-events/LeaseEvents';
import NotificationsList from './components/notifications/NotificationsList';
import PartnersBalanceList from './components/partners-balance/PartnersBalanceList';
import { LeasesList } from './components/leases/Leases';
import { LeaseView } from './components/leases/LeaseView';
import { PromoCodeCreate, PromoCodeEdit, PromoCodeList } from './components/promoCodes';
import { PromotionCreate, PromotionEdit, PromotionList } from './components/promotion';
import { StationCreate, StationEdit, StationList } from './components/stations/Stations';
import { PartnersCreate, PartnersEdit, PartnersList } from './components/partners/Partners';
import { LocationCreate, LocationEdit, LocationsList } from './components/locations/Locations';
import { PowerbankCreate, PowerbankEdit, PowerbankList } from './components/powerbanks/Powerbanks';
import { CampaignsCreate, CampaignsEdit, CampaignsList } from './components/campaigns/Campaigns';
import {
  VoucherTemplateCreate,
  VoucherTemplateEdit,
  VoucherTemplateList,
} from './components/VoucherTemplate/VoucherTemplate';
import { VoucherCreate, VoucherEdit, VoucherList } from './components/VoucherTemplate/Voucher';
import { PriceCreate, PriceEdit, PriceList } from './components/price/index';
import { CurrencyCreate, CurrencyEdit, CurrencyList } from './components/currencies/index';
import { CountryCreate, CountryEdit, CountryList } from './components/countries/index';
import {
  LocationCategoriesCreate,
  LocationCategoriesEdit,
  LocationCategoriesList,
} from './components/location-categories/LocationCategories';
import {
  TopupTemplateList,
  TopupTemplateCreate,
  TopupTemplateEdit,
} from './components/topup-template';
import { FeesCreate, FeesEdit, FeesList } from './components/fees';
import { StationHistoryList } from './components/stationHistory/StationHistory';
import {
  SubscriptionConfigCreate,
  SubscriptionConfigEdit,
  SubscriptionConfigList,
} from './components/subscription-config/index';
import { StationsRackCreate, StationsRackEdit, StationsRackList } from './components/stations-rack';
import {
  DayPassConfigCreate,
  DayPassConfigEdit,
  DayPassConfigList,
} from "./components/daypass-config";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
      'x-react': true,
    });
  }
  const token = localStorage.getItem('authToken');
  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
  }
};

const dataProvider = jsonServerProvider(config.api, httpClient);

const App = () => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <Admin
      menu={MenuList}
      dashboard={Dashboard}
      loginPage={LoginPage}
      authProvider={AuthClient}
      dataProvider={dataProvider}
    >
      <Resource
        name={config.routes.locations}
        list={LocationsList}
        create={LocationCreate}
        edit={LocationEdit}
        icon={LocationCity}
      />
      <Resource
        name={config.routes.locationCategories}
        list={LocationCategoriesList}
        create={LocationCategoriesCreate}
        edit={LocationCategoriesEdit}
        icon={LocalOffer}
        options={{ label: 'Location categories' }}
      />
      <Resource
        name={config.routes.campaigns}
        list={CampaignsList}
        create={CampaignsCreate}
        edit={CampaignsEdit}
        icon={Games}
      />
      <Resource
        name={config.routes.voucherTemplates}
        list={VoucherTemplateList}
        create={VoucherTemplateCreate}
        edit={VoucherTemplateEdit}
        icon={LocalActivity}
      />
      <Resource
        name={config.routes.vouchers}
        list={VoucherList}
        create={VoucherCreate}
        edit={VoucherEdit}
        icon={LocalActivity}
      />
      <Resource
        name={config.routes.topupTemplate}
        list={TopupTemplateList}
        create={TopupTemplateCreate}
        edit={TopupTemplateEdit}
        icon={AccountBalanceWallet}
      />
      <Resource name={config.routes.discounts} list={DiscountsList} icon={Vibration} />
      <Resource
        name={config.routes.partners}
        list={PartnersList}
        create={PartnersCreate}
        edit={PartnersEdit}
        icon={Language}
      />
      <Resource
        name={config.routes.partnersBalance}
        list={PartnersBalanceList}
        options={{ label: 'Partners balance' }}
        icon={AccountBalanceWallet}
      />
      <Resource
        name={config.routes.countries}
        list={CountryList}
        create={CountryCreate}
        edit={CountryEdit}
        options={{ label: 'Countries' }}
        icon={AttachMoney}
      />
      <Resource
        name={config.routes.currencies}
        list={CurrencyList}
        create={CurrencyCreate}
        edit={CurrencyEdit}
        options={{ label: 'Currencies' }}
        icon={AttachMoney}
      />
      <Resource
        name={config.routes.prices}
        list={PriceList}
        create={PriceCreate}
        edit={PriceEdit}
        options={{ label: 'Prices' }}
        icon={AttachMoney}
      />
      <Resource
        name={config.routes.promotions}
        list={PromotionList}
        create={PromotionCreate}
        edit={PromotionEdit}
        icon={Redeem}
      />
      <Resource
        name={config.routes.stations}
        list={StationList}
        create={StationCreate}
        edit={StationEdit}
        icon={EvStation}
      />
      <Resource
        name={config.routes.powerbanks}
        list={PowerbankList}
        create={PowerbankCreate}
        edit={PowerbankEdit}
        icon={Power}
      />
      <Resource name={config.routes.leases} list={LeasesList} edit={LeaseView} icon={Receipt} />
      <Resource
        name={config.routes.leasesEvents}
        list={LeasesEventsList}
        icon={FlashOn}
        options={{ label: 'Events' }}
      />
      <Resource
        name={config.routes.notificationStore}
        list={NotificationsList}
        icon={Notifications}
        options={{ label: 'Notifications' }}
      />
      <Resource
        name={config.routes.promoCodes}
        list={PromoCodeList}
        create={PromoCodeCreate}
        edit={PromoCodeEdit}
        icon={Code}
      />
      <Resource
        name={config.routes.fees}
        list={FeesList}
        create={FeesCreate}
        edit={FeesEdit}
        icon={AttachMoney}
      />
      <Resource
        name={config.routes.subscriptionConfig}
        list={SubscriptionConfigList}
        create={SubscriptionConfigCreate}
        edit={SubscriptionConfigEdit}
        icon={Subscriptions}
        options={{ label: 'Subscription configs' }}
      />
      <Resource
        name={config.routes.stationsRack}
        list={StationsRackList}
        create={StationsRackCreate}
        edit={StationsRackEdit}
        icon={ViewModule}
        options={{ label: 'Stations rack' }}
      />
      <Resource
        name={config.routes.dayPassConfig}
        list={DayPassConfigList}
        create={DayPassConfigCreate}
        edit={DayPassConfigEdit}
        icon={Redeem}
        options={{ label: 'DayPass configs' }}
      />
      <Resource name="user" list={UserList} show={UserShow} edit={UserEdit} icon={Person} />
      <Resource name="balance" create={AddBalance} />
      <Resource name="station-history" create={StationHistoryList} />
    </Admin>
  </MuiPickersUtilsProvider>
);

export default App;
