import React, { useState } from 'react';
import {
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,

  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  required,
} from 'react-admin';

import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { DateTimeInput } from 'react-admin-date-inputs';

import { renderFieldUserInfo } from '../../utils/users';
import config from '../../config';
import httpClient from '../../api/instance';

import './styles.css';

export const LeaseView = (props) => {
  const [isRecalculateLeaseModalVisible, setIsRecalculateLeaseModalVisible] = useState(false);

  return (
    <>
      <Show {...props} actions={false}>
        <SimpleShowLayout>
          <ReferenceField label="Linked location" source="sourceLocationId" reference="location">
            <TextField source="internalName" />
          </ReferenceField>

          <ReferenceField
            allowEmpty
            label="Source station"
            source="sourceStation.id"
            reference="station"
          >
            <TextField source="hardwareId" />
          </ReferenceField>

          <ReferenceField
            allowEmpty
            label="Return station"
            source="returnStation.id"
            reference="station"
          >
            <TextField source="hardwareId" />
          </ReferenceField>

          <ReferenceField allowEmpty label="Powerbank" source="powerbank.id" reference="powerbank">
            <TextField source="hardwareId" />
          </ReferenceField>

          <FunctionField label="User" allowEmpty render={renderFieldUserInfo} />
          <TextField source="userId" label="User #" />
          <TextField source="status" />
          <TextField source="statusReason" />
          <DateField source="createdAt" showTime locales="en-GB" />
          <DateField source="extractTime" showTime locales="en-GB" />
          <DateField source="returnTime" showTime locales="en-GB" />
          <Button
            variant="raised"
            color="primary"
            onClick={() => setIsRecalculateLeaseModalVisible(!isRecalculateLeaseModalVisible)}
          >Recalculate return</Button>
        </SimpleShowLayout>
      </Show>
      <Dialog
        open={isRecalculateLeaseModalVisible}
        onDismiss={() => setIsRecalculateLeaseModalVisible(false)}
      >
        <RecalculateReturnLease leaseId={props.id} onSuccess={() => setIsRecalculateLeaseModalVisible(false)}/>
      </Dialog>
    </>
  );
};

const RecalculateReturnLease = ({
  leaseId,
  onSuccess,
}) => {
  const [estimatedFee, setEstimatedFee] = useState();

  const estimateFee = async (args) => {
    const newEstimatedFee = await httpClient.get(
      `${config.routes.leases}/${leaseId}/estimate`,
      {
        params: new URLSearchParams(args),
        baseURL: config.api,
      },
    );

    setEstimatedFee(newEstimatedFee.data);
  };

  const handleSubmit = async (args) => {
    await httpClient.get(
      `${config.routes.leases}/${leaseId}/recalculate`,
      {
        params: new URLSearchParams(args),
        baseURL: config.api,
      },
    );

    onSuccess();
  };

  return (
    <>
      <DialogTitle>
        Estimated lease fee: {estimatedFee && `${estimatedFee.fee / 100} ${estimatedFee.currency}`}
      </DialogTitle>

      <DialogContent>
        <SimpleForm
          save={handleSubmit}
          validate={estimateFee}
        >
          <SelectInput
            label="Return status"
            source="returnStatus"
            choices={[
              {
                id: 'RETURNED',
                name: 'RETURNED',
              },
              {
                id: 'STOLEN',
                name: 'STOLEN',
              },
            ]}
            validate={required()}
          />

          <DateTimeInput
            label="Return time"
            source="returnTime"
            options={{
              format: 'yyyy MMM dd HH:mm',
              ampm: false,
            }}
          />

          <ReferenceInput
            label="Return station"
            source="returnStationId"
            reference="station"
          >
            <AutocompleteInput optionText="hardwareId" optionValue="id" />
          </ReferenceInput>
        </SimpleForm>
      </DialogContent>
    </>
  );
};
