import axios from 'axios';
import config from '../config';

const token = localStorage.getItem('authToken');

const instance = axios.create({
  baseURL: config.api,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export default instance;
