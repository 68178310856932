import React, { useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  FunctionField,
  Pagination,
  Filter,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  EditButton,
} from 'react-admin';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { getLeaseTransactions } from '../../api';
import ExportAction from './ExportAction';
import getLeaseLogs from '../../api/lease-logs';
import {LeaseStatuses, PowerbankStatuses} from '../../config/constants';
import { renderFieldUserInfo, renderUserInfo } from '../../utils/users';
import config from '../../config';

const LeasePagination = (props) => {
  return <Pagination {...props} rowsPerPageOptions={[50, 100]} />;
};

const LeasesFilters = (props) => {
  const now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  const lastWeek = new Date(now.getTime());
  const last2Weeks = new Date(now.getTime());
  const lastMonth = new Date(now.getTime());
  const last3Months = new Date(now.getTime());
  const last6Months = new Date(now.getTime());
  const lastYear = new Date(now.getTime());

  lastWeek.setDate(now.getDate() - 7);
  last2Weeks.setDate(now.getDate() - 14);
  lastMonth.setMonth(now.getMonth() - 1);
  last3Months.setMonth(now.getMonth() - 3);
  last6Months.setMonth(now.getMonth() - 6);
  lastYear.setFullYear(now.getFullYear() - 1);

  return (
    <Filter {...props}>
      <SelectInput
        source="createdAt"
        label="Time span"
        alwaysOn
        resettable
        choices={[
          { id: lastWeek.toISOString(), name: 'Last 1 week' },
          { id: last2Weeks.toISOString(), name: 'Last 2 weeks' },
          { id: lastMonth.toISOString(), name: 'Last 1 month' },
          { id: last3Months.toISOString(), name: 'Last 3 months' },
          { id: last6Months.toISOString(), name: 'Last 6 months' },
          { id: lastYear.toISOString(), name: 'Last 1 year' },
        ]}
      />

      <ReferenceInput
        label="Location"
        source="sourceLocationId"
        reference="location"
        alwaysOn
        perPage={50}
      >
        <AutocompleteInput optionText="internalName" optionValue="id" />
      </ReferenceInput>

      <ReferenceInput
        label="Source station"
        source="sourceStationId"
        reference="station"
        alwaysOn
        perPage={50}
      >
        <AutocompleteInput optionText="hardwareId" optionValue="id" />
      </ReferenceInput>

      <ReferenceInput
        label="Return station"
        source="returnStationId"
        reference="station"
        alwaysOn
        perPage={50}
      >
        <AutocompleteInput optionText="hardwareId" optionValue="id" />
      </ReferenceInput>

      <ReferenceInput
        label="Powerbank"
        source="powerbankId"
        reference="powerbank"
        alwaysOn
        perPage={50}
      >
        <AutocompleteInput optionText="hardwareId" optionValue="id" />
      </ReferenceInput>

      <SelectInput
        label="Powerbank status"
        source="powerbank.status"
        alwaysOn
        resettable
        choices={PowerbankStatuses.map((status) => ({
          id: status,
          name: status,
        }))}
      />

      <ReferenceInput label="User" source="userId" reference="user" alwaysOn perPage={50}>
        <AutocompleteInput optionText={renderUserInfo} optionValue="id" />
      </ReferenceInput>

      <SelectInput
        source="status"
        alwaysOn
        resettable
        choices={LeaseStatuses.map((status) => ({
          id: status,
          name: status,
        }))}
      />
    </Filter>
  );
};

export const LeasesList = (props) => {
  const [txModal, setTxModal] = useState(false);
  const [eventsModal, setEventsModal] = useState(false);
  const [currentLeaseId, setCurrentLeaseId] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [events, setEvents] = useState([]);

  const handleTransactionClick = async (id) => {
    setCurrentLeaseId(id);
    try {
      const { data = [] } = await getLeaseTransactions(id);
      setTransactions(data);
      setTxModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEventsClick = async (id) => {
    setCurrentLeaseId(id);
    try {
      const { data = [] } = await getLeaseLogs(id);
      setEvents(data);
      setEventsModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const closeTxModal = () => {
    setTxModal(false);
    setTransactions([]);
  };

  const refundAmount = (transactionId) => {
    console.log('transacitonId', transactionId);
    fetch(
      `${config.api}/mobile-pay/refund/${transactionId}`,
      {
        method: 'post',
        body: JSON.stringify({
          grant_type: 'refresh_token',
          refresh_token: localStorage.getItem('refreshToken'),
        }),
      },
    );

    return;
  };

  const closeEventsModal = () => {
    setEventsModal(false);
    setEvents([]);
  };

  return (
    <>
      <Dialog
        open={txModal}
        onClose={closeTxModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          Transactions for lease
          {currentLeaseId}
        </DialogTitle>
        <DialogContent>
          {!!transactions.length && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="right">type</TableCell>
                  <TableCell align="right">status</TableCell>
                  <TableCell align="right">amount</TableCell>
                  <TableCell align="right">currency</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.type}
                    </TableCell>
                    <TableCell align="right">{row.status}</TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                    <TableCell align="right">{row.currency}</TableCell>
                    { row.referenceId.includes('mobile-pay') ? (
                      <Button onClick={() => refundAmount(row.id)} color="error">
                        Refund
                      </Button>
                      ) : (
                        <></>
                      )
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {!transactions.length && "Transactions don't exist"}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeTxModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={eventsModal}
        onClose={closeEventsModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          Events for lease
          {currentLeaseId}
        </DialogTitle>
        <DialogContent>
          {!!events.length && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="right">timestamp</TableCell>
                  <TableCell align="right">event</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {moment(row.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell align="right">{row.event}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {!events.length && "Events don't exist"}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEventsModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <List
        {...props}
        sort={{ field: 'createdAt', order: 'DESC' }}
        actions={<ExportAction />}
        pagination={<LeasePagination />}
        perPage={100}
        filters={<LeasesFilters />}
      >
        <Datagrid>
          <ReferenceField label="Linked location" source="sourceLocationId" reference="location">
            <TextField source="internalName" />
          </ReferenceField>

          <ReferenceField
            allowEmpty
            label="Source station"
            source="sourceStation.id"
            reference="station"
          >
            <TextField source="hardwareId" />
          </ReferenceField>

          <ReferenceField
            allowEmpty
            label="Return station"
            source="returnStation.id"
            reference="station"
          >
            <TextField source="hardwareId" />
          </ReferenceField>

          <ReferenceField allowEmpty label="Powerbank" source="powerbank.id" reference="powerbank">
            <TextField source="hardwareId" />
          </ReferenceField>

          <FunctionField label="User" allowEmpty render={renderFieldUserInfo} />
          <TextField source="userId" label="User #" />
          <TextField source="status" />
          <TextField source="statusReason" />
          <DateField source="createdAt" showTime locales="en-GB" />
          <DateField source="extractTime" showTime locales="en-GB" />
          <DateField source="returnTime" showTime locales="en-GB" />
          <ModalTxBtn onClick={handleTransactionClick} />
          <ModalEventsBtn onClick={handleEventsClick} />
          <EditButton label="View" />
        </Datagrid>
      </List>
    </>
  );
};

const ModalTxBtn = (props) => {
  const { record } = props;
  const { id } = record;
  const { onClick } = props;
  return (
    <Button color="primary" onClick={(e) => onClick(id)}>
      <EyeIcon style={{ marginRight: '10px' }} />
      Transactions
    </Button>
  );
};

const ModalEventsBtn = (props) => {
  const { record } = props;
  const { id } = record;
  const { onClick } = props;
  return (
    <Button color="primary" onClick={(e) => onClick(id)}>
      <EyeIcon style={{ marginRight: '10px' }} />
      Events
    </Button>
  );
};

ModalTxBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

ModalEventsBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};
