import React from 'react';
import {
  List,
  Create,
  Edit,
  SimpleForm,
  Datagrid,
  EditButton,
  TextField,
  required,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

export const FeesList = (props) => (
  <List {...props} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid>
      <ReferenceField
        label="Country"
        reference="country"
        source="countryId"
      >
        <TextField
          label="Country"
          source="code"
        />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

export const FeesCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          label="Country"
          reference="country"
          source="countryId"
          validate={[required()]}
        >
          <AutocompleteInput optionText={(record) => record.name} />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export const FeesEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Country"
        reference="country"
        source="countryId"
        validate={[required()]}
      >
        <AutocompleteInput optionText={(record) => record.name} />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
