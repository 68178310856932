import PropTypes, { number } from 'prop-types';
import React from 'react';
import {
  CardActions,
  Create,
  CreateButton,
  Datagrid,
  Edit,
  EditButton,
  List,
  RefreshButton,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';

const LocationCategoriesActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </CardActions>
);

LocationCategoriesActions.propTypes = {
  basePath: PropTypes.string,
};

export const LocationCategoriesList = (props) => {
  return (
    <List {...props} actions={<LocationCategoriesActions />} sort={{ field: 'id', order: 'ASC' }}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const LocationCategoriesCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Create>
  );
};

export const LocationCategoriesEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Edit>
  );
};

LocationCategoriesEdit.propTypes = {
  id: number,
};
