import React, { Fragment, useState } from 'react';
import {
  CreateButton,
  RefreshButton,
  CardActions,
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  EditButton,
  TabbedForm,
  ReferenceManyField,
  FormTab,
  FunctionField,
  BooleanInput,
  SimpleFormIterator,
  ArrayInput,
  required,
  Filter,
  AutocompleteInput,
  SingleFieldList,
  ChipField,
  FormDataConsumer,
  REDUX_FORM_NAME,
  ReferenceInput,
  ReferenceField,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  NumberInput,
  Confirm,
} from 'react-admin';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as moment from 'moment';
import { Button } from '@material-ui/core';
import { change } from 'redux-form';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import ExcelReader from './ExcelReader';
import JSONInput from '../JSONInput';
import ImageInputFirebase from '../imageFirebaseInput';
import GMap from '../GMap';
import LocationsMap from '../LocationsMap';
import countries from '../../utils/countries.json';
import config from '../../config';
import { RaTimePicker } from '../ra-time-picker';

const styles = {
  selector: {
    zIndex: 2100,
  },
};

const PriceType = {
  WEB_APP: 'WEB_APP',
  APP: 'APP',
};

const applyGoogleData = (dispatch, googlePlace) => {
  if (!Object.entries(googlePlace).length) {
    return;
  }

  const findAddressComponent = (component, shortName = false) => {
    const found = googlePlace.address_components.find((el) => el.types.includes(component));

    if (!found) {
      return '';
    }

    return shortName ? found.short_name : found.long_name;
  };

  dispatch(change(REDUX_FORM_NAME, 'name', googlePlace.name));
  dispatch(change(REDUX_FORM_NAME, 'internalName', googlePlace.name));
  dispatch(
    change(
      REDUX_FORM_NAME,
      'address1',
      googlePlace ? googlePlace.vicinity.split(', ').slice(0, -1).join(', ') : '',
    ),
  );
  dispatch(change(REDUX_FORM_NAME, 'website', googlePlace.website));
  dispatch(change(REDUX_FORM_NAME, 'phone', googlePlace.international_phone_number));
  dispatch(change(REDUX_FORM_NAME, 'city', findAddressComponent('locality')));
  dispatch(change(REDUX_FORM_NAME, 'state', findAddressComponent('administrative_area_level_1')));
  dispatch(change(REDUX_FORM_NAME, 'country', findAddressComponent('country', true)));
  dispatch(change(REDUX_FORM_NAME, 'zip', findAddressComponent('postal_code')));
};

const LocationActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </CardActions>
);

LocationActions.propTypes = {
  basePath: PropTypes.string.isRequired,
};

const LocationFilter = (props) => (
  <Filter {...props}>
    <TextInput alwaysOn source="internalName" resettable />

    <ReferenceInput source="partnerId" reference="partner" alwaysOn perPage={50} resettable>
      <AutocompleteInput optionText="name" optionValue="id" resettable />
    </ReferenceInput>

    <AutocompleteInput
      source="country"
      alwaysOn
      resettable
      allowEmpty
      choices={countries.map((el) => {
        return {
          id: el['alpha-2'],
          name: el.name,
        };
      })}
    />
  </Filter>
);

export const LocationsList = (props) => {
  const [tab, setTab] = useState(0);

  return (
    <Fragment>
      <Tabs value={tab} onChange={(e, val) => setTab(val)}>
        <Tab label="List" />
        <Tab label="Map" />
      </Tabs>
      {tab === 0 && (
        <List
          {...props}
          actions={<LocationActions />}
          sort={{ field: 'id', order: 'ASC' }}
          filters={<LocationFilter />}
        >
          <Datagrid>
            <TextField source="id" />
            <TextField source="internalName" label="Internal Name" />
            <ReferenceField label="Partner" source="partnerId" reference="partner" allowEmpty>
              <TextField source="name" />
            </ReferenceField>
            <TextField source="description" label="Venue Desc." />
            <ReferenceField
              label="Price"
              reference="price"
              source="priceId"
              allowEmpty
            >
              <FunctionField label="Price" render={(record) => `${record.rateFee / 100} ${record.currency}`} />
            </ReferenceField>
            <ReferenceField
              label="Web app price"
              reference="price"
              source="webappPriceId"
              allowEmpty
            >
              <FunctionField label="Price" render={(record) => `${record.rateFee / 100} ${record.currency}`} />
            </ReferenceField>
            <ReferenceField
              label="Country"
              reference="country"
              source="countryRefId"
              allowEmpty
            >
              <TextField label="Country" source="name" />
            </ReferenceField>
            <TextField source="city" label="City" />
            <TextField source="address1" label="Address 1" />
            <TextField source="address2" label="Address 2" />
            <TextField source="phone" label="Phone" />
            <TextField source="website" label="Website" />
            <FunctionField label="Private" render={(record) => (record.isPrivate ? 'Yes' : 'No')} />
            <FunctionField label="Allow promotion" render={(record) => (record.allowPromotion ? 'Yes' : 'No')} />
            <ReferenceManyField label="Station" reference="station" target="locationId" perPage="9">
              <SingleFieldList>
                <ChipField source="hardwareId" />
              </SingleFieldList>
            </ReferenceManyField>
            <EditButton label="" />
            <DeleteButtonWithSelector />
          </Datagrid>
        </List>
      )}
      {tab === 1 && (
        <LocationsMap
          source="coordinates"
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.googleKey}
            &v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '400px' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          endpoint="location"
        />
      )}
    </Fragment>
  );
};

const processDelete = async (locationId, newLocationId) => {
  if (newLocationId) {
    const deleteRequest = await fetch(`${config.api}/location/${locationId}?newLocationId=${newLocationId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        'x-react': true,
      },
      method: 'DELETE',
    });

    if (deleteRequest && deleteRequest.status === 200) {
      window.location.reload();
    }
  }
};

export const DeleteButtonWithSelector = withStyles(styles)(({ ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newLocationId, setNewLocationId] = useState(0);

  const handleClick = e => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const handleDialogClose = e => {
    e.stopPropagation();
    setIsOpen(false);
  };

  return (
    <>
      <Button
        className='ra-delete-button'
        label="delete"
        onClick={(event) => handleClick(event)}
      >
        <DeleteIcon color="error" />
      </Button>
      <Confirm
        title="Location may have stations. Select other location to reassign stations."
        isOpen={isOpen}
        content={(
          <SimpleForm toolbar="">
            <FormDataConsumer>
              {
            (formProps) => {
              setNewLocationId(formProps.formData.id);
              return (
                <ReferenceInput
                  label="Select Location"
                  reference="location"
                  source="id"
                  allowEmpty
                  validate={[required()]}
                >
                  <AutocompleteInput
                    optionText={(newLocation) => {
                      return newLocation.internalName;
                    }}
                    className={styles.selector}
                  />
                </ReferenceInput>
              );
            }
          }
            </FormDataConsumer>
          </SimpleForm>
      )}
        onConfirm={() => {
          processDelete(props.record.id, newLocationId);
          setIsOpen(false);
        }}
        onClose={(event) => handleDialogClose(event)}
      />
    </>
  );
});

export const LocationCreate = (props) => {
  const [googlePlace, setGooglePlace] = useState({});

  return (
    <Create {...props}>
      <SimpleForm>
        <br />
        <FormDataConsumer>
          {({ formData, dispatch }) => {
            if (Object.entries(googlePlace).length) {
              formData.googlePlaceId = googlePlace.place_id;
            }

            return (
              <Button onClick={() => applyGoogleData(dispatch, googlePlace)}>
                Load info from Google
              </Button>
            );
          }}
        </FormDataConsumer>

        <TextInput source="name" label="Venue Name" />
        <TextInput source="internalName" label="Internal Venue Name" />

        <ReferenceArrayInput label="Categories" reference="location-category" source="categoryIds">
          <AutocompleteArrayInput />
        </ReferenceArrayInput>

        <ReferenceArrayInput
          label="Add new stations"
          reference="station"
          source="newStationIds"
          allowEmpty
        >
          <AutocompleteArrayInput optionText={(record) => record.hardwareId} />
        </ReferenceArrayInput>
        <ReferenceInput
          filter={{ priceType: PriceType.APP }}
          label="Add price"
          reference="price"
          source="priceId"
          perPage={1000}
          allowEmpty
        >
          <AutocompleteInput optionText={(record) => record.name} />
        </ReferenceInput>
        <ReferenceInput
          filter={{ priceType: PriceType.WEB_APP }}
          label="Add Web app price"
          reference="price"
          source="webappPriceId"
          allowEmpty
        >
          <AutocompleteInput optionText={(record) => record.name} />
        </ReferenceInput>
        <ReferenceInput label="Partner" source="partnerId" reference="partner">
          <AutocompleteInput />
        </ReferenceInput>
        <TextInput source="description" label="Venue Description" />
        <ReferenceInput
          label="Country"
          reference="country"
          source="countryRefId"
          validate={[required()]}
        >
          <AutocompleteInput optionText={(record) => record.name} />
        </ReferenceInput>
        <TextInput source="state" label="State" />
        <TextInput source="city" label="City" />
        <TextInput source="address1" label="Address (Line 1)" />
        <TextInput source="address2" label="Address (Line 2)" />
        <TextInput source="zip" label="Postal Code" />
        <TextInput source="phone" label="Phone" />
        <TextInput source="website" label="Website" />
        <BooleanInput source="isPrivate" label="Private" />
        <BooleanInput source="allowPromotion" label="Allow promotion" />
        <JSONInput
          googleSchedule={googlePlace.opening_hours ? googlePlace.opening_hours.periods : {}}
        />
        <ImageInputFirebase />
        <TextInput source="googlePlaceId" label="Google Place ID" disabled />
        <GMap
          source="coordinates"
          googleKey={config.googleKey}
          setGooglePlace={setGooglePlace}
          searchable
        />
      </SimpleForm>
    </Create>
  );
};

const LocationTitle = ({ record }) => <span>{record ? `Location ${record.name}` : ''}</span>;

export const LocationEdit = (props) => {
  const onFileParse = (ids) => {
    fetch(`${config.api}/station/bulk/${props.id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
      method: 'POST',
      body: JSON.stringify({ ids }),
    });
  };

  const [googlePlace, setGooglePlace] = useState({});

  const validateUrl = [required()];

  return (
    <Edit title={<LocationTitle />} {...props}>
      <TabbedForm>
        <FormTab label="location">
          <br />
          <FormDataConsumer>
            {({ formData, dispatch }) => {
              if (Object.entries(googlePlace).length) {
                formData.googlePlaceId = googlePlace.place_id;
              }

              return (
                <Button onClick={() => applyGoogleData(dispatch, googlePlace)}>
                  Load info from Google
                </Button>
              );
            }}
          </FormDataConsumer>
          <TextInput source="name" label="Venue Name" />
          <TextInput source="internalName" label="Internal Venue Name" />

          <ReferenceArrayInput
            label="Categories"
            reference="location-category"
            source="categoryIds"
            perPage={100}
          >
            <AutocompleteArrayInput />
          </ReferenceArrayInput>
          <ReferenceInput
            filter={{ priceType: PriceType.APP }}
            label="Add price"
            reference="price"
            source="priceId"
            allowEmpty
          >
            <AutocompleteInput optionText={(record) => record.name} />
          </ReferenceInput>
          <ReferenceInput
            filter={{ priceType: PriceType.WEB_APP }}
            label="Add Web app price"
            reference="price"
            source="webappPriceId"
            allowEmpty
          >
            <AutocompleteInput optionText={(record) => record.name} />
          </ReferenceInput>
          <ReferenceInput label="Partner" source="partnerId" reference="partner">
            <AutocompleteInput />
          </ReferenceInput>

          <ReferenceArrayInput
            label="Add new stations"
            reference="station"
            source="newStationIds"
            allowEmpty
          >
            <AutocompleteArrayInput optionText={(record) => record.hardwareId} />
          </ReferenceArrayInput>

          <TextInput source="description" label="Venue Description" />
          <NumberInput source="hourlyRate" />

          <ReferenceInput
            label="Country"
            reference="country"
            source="countryRefId"
            validate={[required()]}
          >
            <AutocompleteInput optionText={(record) => record.name} />
          </ReferenceInput>
          <TextInput source="state" label="State" />
          <TextInput source="city" label="City" />
          <TextInput source="address1" label="Address (Line 1)" />
          <TextInput source="address2" label="Address (Line 2)" />
          <TextInput source="zip" label="Postal Code" />
          <TextInput source="phone" label="Phone" />
          <TextInput source="website" label="Website" />
          <BooleanInput source="isPrivate" label="Private" />
          <BooleanInput source="allowPromotion" label="Allow promotion" />
          <JSONInput
            googleSchedule={googlePlace.opening_hours ? googlePlace.opening_hours.periods : {}}
          />
          <ImageInputFirebase />
          <TextInput source="googlePlaceId" label="Google Place ID" disabled />
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <GMap
                  source="coordinates"
                  googleKey={config.googleKey}
                  initialPlaceId={formData.googlePlaceId}
                  setGooglePlace={setGooglePlace}
                  searchable
                />
              );
            }}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="stations">
          <ExcelReader onParse={onFileParse} />
          <ReferenceManyField label="Station" reference="station" target="locationId">
            <Datagrid>
              <TextField source="id" />
              <TextField source="hardwareId" />
              <TextField source="status" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="links">
          <ArrayInput source="links">
            <SimpleFormIterator>
              <TextInput label="Url" source="url" validate={validateUrl} />
              <TextInput label="Text" source="text" />

              <RaTimePicker
                source="startTime"
                label="Start Time"
                keyboard
                ampm={false}
                parse={(v) => {
                  if (!v) {
                    return undefined;
                  }

                  return v.format('HH:mm');
                }}
                format={(v) => {
                  if (!v) {
                    return undefined;
                  }

                  return moment(`2020-01-01 ${v}`).toISOString();
                }}
                mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                disableOpenOnEnter
              />
              <RaTimePicker
                source="endTime"
                label="End Time"
                keyboard
                ampm={false}
                parse={(v) => {
                  if (!v) {
                    return undefined;
                  }

                  return v.format('HH:mm');
                }}
                format={(v) => {
                  if (!v) {
                    return undefined;
                  }

                  return moment(`2020-01-01 ${v}`).toISOString();
                }}
                mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                disableOpenOnEnter
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

LocationEdit.propTypes = {
  id: PropTypes.number.isRequired,
};

LocationTitle.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};
