import React from 'react';
import { addField, FieldTitle } from 'ra-core';
import { TimePicker } from 'material-ui-pickers';

const makePicker = (PickerComponent) => {
  class _makePicker extends React.Component {
    onChange(date) {
      this.props.input.onChange(date);
      this.props.input.onBlur();
    }

    render() {
      const {
        input,
        label,
        source,
        resource,
        className,
        meta,
      } = this.props;

      const { touched, error } = meta;

      return (<PickerComponent
        {...this.props}
        label={<FieldTitle
          label={label}
          source={source}
          resource={resource}
        />}

        margin="normal"
        error={!!(touched && error)}
        helperText={touched && error}
        ref={(node) => { this.picker = node; }}
        className={className}
        value={input.value ? input.value : null}
        onChange={date => this.onChange(date)}
      />)
    }
  }

  return _makePicker;
}


export const RaTimePicker = addField(makePicker(TimePicker));