import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { userLogin, Notification } from 'react-admin';
import firebase from 'firebase';
import GoogleButton from 'react-google-button';
import PropTypes from 'prop-types';

class LoginPage extends Component {
  componentDidMount() {
    const { pushPB } = this.props;
    if (firebase.auth().currentUser) {
      return pushPB('/powerbank');
    }
  }

  render() {
    const { userLog } = this.props;
    return (
      <div style={{ marginTop: 300 }}>
        <GoogleButton onClick={userLog} style={{ margin: 'auto' }}>
          LOGIN
        </GoogleButton>
        <Notification />
      </div>
    );
  }
}

LoginPage.propTypes = {
  pushPB: PropTypes.func,
  userLog: PropTypes.func,
};

export default connect(null, { userLog: userLogin, pushPB: push })(LoginPage);
