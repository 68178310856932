import React from 'react';
import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  EditButton,
  DateField,
  BooleanInput,
  SelectInput,
  ReferenceField,
  BooleanField,
  required,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';
import { PromotionTypes } from '../../config/constants';

export const PromotionList = (props) => (
  <List {...props} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid>
      <BooleanField label="Active" source="isActive" />
      <TextField source="title" label="Title" />
      <TextField source="type" label="Type" />
      <ReferenceField
        label="Partner"
        source="partnerId"
        reference="partner"
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField source="startDate" label="Start date" />
      <DateField source="endDate" label="End date" />
      <EditButton />
    </Datagrid>
  </List>
);

export const PromotionCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <BooleanInput label="Is enabled" source="isActive" />
        <TextInput source="title" label="Title" />
        <SelectInput
          source="type"
          choices={PromotionTypes}
        />
        <ReferenceInput label="Partner" source="partnerId" reference="partner" alwaysOn perPage={50}>
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <DateInput
          source="startDate"
          label="Start Date"
          options={{ format: 'dd/MM/YYYY' }}
          validate={[required()]}
        />
        <DateInput
          source="endDate"
          label="End Date"
          options={{ format: 'dd/MM/YYYY' }}
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};

export const PromotionEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput label="Is enabled" source="isActive" />
      <TextInput source="title" label="Title" />
      <SelectInput
        source="type"
        choices={PromotionTypes}
      />
      <ReferenceInput label="Partner" source="partnerId" reference="partner" alwaysOn perPage={50}>
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <DateInput
        source="startDate"
        label="Start Date"
        options={{ format: 'dd/MM/YYYY' }}
        validate={[required()]}
      />
      <DateInput
        source="endDate"
        label="End Date"
        options={{ format: 'dd/MM/YYYY' }}
        validate={[required()]}
      />
    </SimpleForm>
  </Edit>
);
