import { string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { chartHistory } from '../../../config/constants';
import {getStationsHistory} from "../../../api/station-history";

const StationsHistoryChart = ({ fromDate, toDate, stationId }) => {
  const [data, setData] = useState({});

  const fetchData = async () => {
    const { data: response } = await getStationsHistory(new Date(fromDate), new Date(toDate), stationId);
    console.log('response', response);
    setData({
      datasets: [
        {
          label: 'Online',
          fill: false,
          backgroundColor: 'rgb(36,168,0)',
          borderColor: 'rgb(0,0,0)',
          borderWidth: 1,
          data: response.stations.map((el) => {
            if (el.status === 'ON') {
              return { x: new Date(el.createdAt), y:1 }
            }
            return {};
          }),
        },
        {
          label: 'Offline',
          fill: false,
          backgroundColor: 'rgb(168,0,0)',
          borderColor: 'rgb(0,0,0)',
          borderWidth: 1.5,
          data: response.stations.map((el) => {
            if (el.status === 'OFF') {
              return { x: new Date(el.createdAt), y:1 }
            }
            return {};
          }),
        },
      ],
    });
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate]);

  return <Bar data={data} options={chartHistory} />;
};

StationsHistoryChart.propTypes = {
  fromDate: string,
  toDate: string,
};

export default StationsHistoryChart;
