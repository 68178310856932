import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withScriptjs } from 'react-google-maps';
import { StandaloneSearchBox } from 'react-google-maps/lib/components/places/StandaloneSearchBox';

class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  initGooglePlace = (searchBoxRef) => {
    this.input.current = searchBoxRef;
  };

  onPlacesChanged = () => {
    const {
      input,
      multipleMarkers,
      putMarker,
      setGooglePlace,
    } = this.props;
    const [place] = this.input.current.getPlaces();
    const markerPos = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };

    setGooglePlace(place);
    putMarker({ markerPos, input, multipleMarkers });
  };

  render() {
    const { props, onPlacesChanged } = this;
    const defaultStyle = {
      boxSizing: 'border-box',
      border: '1px solid transparent',
      width: '240px',
      height: '32px',
      padding: '0 12px',
      borderRadius: '3px',
      boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
      fontSize: '14px',
      outline: 'none',
      textOverflow: 'ellipses',
      marginBottom: '15px',
      position: 'absolute',
      top: '15px',
      left: '0px',
      right: '0px',
      margin: '0 auto',
      zIndex: '1',
    };

    return (
      <div data-standalone-searchbox="">
        <StandaloneSearchBox
          ref={this.initGooglePlace.bind(this)}
          bounds={props.bounds}
          onPlacesChanged={onPlacesChanged}
        >
          <input
            type="text"
            placeholder="Search location"
            style={{
              ...defaultStyle,
            }}
          />
        </StandaloneSearchBox>
      </div>
    );
  }
}

SearchBox.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
  }),
  multipleMarkers: PropTypes.bool,
  putMarker: PropTypes.func,
  setGooglePlace: PropTypes.func,
  initialPlaceId: PropTypes.string,
  bounds: PropTypes.objectOf(PropTypes.object),
};

const WithScriptSearchBox = withScriptjs((props) => <SearchBox {...props} />);

export default WithScriptSearchBox;
