import { Button, Grid } from '@material-ui/core';
import PropTypes, { number, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  CardActions,
  Create,
  CreateButton,
  Datagrid,
  Edit,
  EditButton,
  FormDataConsumer,
  FormTab,
  List,
  ReferenceArrayInput,
  ReferenceManyField,
  RefreshButton,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  BooleanInput,
  NumberInput,
  FunctionField,
} from 'react-admin';
import { deleteLocationFromPartner, getPartnersBalance, withdrawMoney } from '../../api/partner';
import countries from '../../utils/countries.json';
import CountryTextField from '../CountryTextField';

const PartnerActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </CardActions>
);

PartnerActions.propTypes = {
  basePath: PropTypes.string,
};

export const PartnersList = (props) => {
  return (
    <List {...props} actions={<PartnerActions />} sort={{ field: 'id', order: 'ASC' }}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" label="Name" />
        <FunctionField label="Private" render={(record) => (record.isPrivate ? 'Yes' : 'No')} />
        <CountryTextField source="country" label="Country" />
        <TextField source="state" label="State" />
        <TextField source="city" label="City" />
        <TextField source="address1" label="Address 1" />
        <TextField source="address2" label="Address 2" />
        <TextField source="zip" label="Postal Code" />
        <TextField source="phone" label="Phone" />
        <TextField source="website" label="Website" />
        <TextField source="email" label="Email" />
        <TextField source="contactPerson" label="Contact Person Name" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const PartnersCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" label="Partner Name" />
        <BooleanInput source="isPrivate" label="Private" />
        <AutocompleteInput
          source="country"
          label="Country"
          choices={countries.map((country) => {
            return {
              id: country['alpha-2'],
              name: country.name,
            };
          })}
        />
        <TextInput source="state" label="State" />
        <TextInput source="city" label="City" />
        <TextInput source="address1" label="Address (Line 1)" />
        <TextInput source="address2" label="Address (Line 2)" />
        <TextInput source="zip" label="Postal Code" />
        <TextInput source="phone" label="Phone" />
        <TextInput source="website" label="Website" />
        <TextInput source="email" label="Email" />
        <TextInput source="contactPerson" label="Contact Person Name" />
      </SimpleForm>
    </Create>
  );
};

export const PartnersEdit = (props) => {
  const [balance, setBalance] = useState({});

  useEffect(() => {
    (async () => {
      const { data } = await getPartnersBalance(props.id);
      setBalance(data);
    })();
  }, []);

  const PartnerTitle = ({ record }) => <span>{record ? `Partner ${record.name}` : ''}</span>;

  PartnerTitle.propTypes = {
    record: shape({
      name: string,
    }),
  };

  return (
    <Edit title={<PartnerTitle />} {...props}>
      <TabbedForm>
        <FormTab label="partner">
          <TextInput source="name" label="Partner Name" />
          <BooleanInput source="isPrivate" label="Private" />
          <AutocompleteInput
            source="country"
            label="Country"
            choices={countries.map((country) => {
              return {
                id: country['alpha-2'],
                name: country.name,
              };
            })}
          />
          <TextInput source="state" label="State" />
          <TextInput source="city" label="City" />
          <TextInput source="address1" label="Address (Line 1)" />
          <TextInput source="address2" label="Address (Line 2)" />
          <TextInput source="zip" label="Postal Code" />
          <TextInput source="phone" label="Phone" />
          <TextInput source="website" label="Website" />
          <TextInput source="email" label="Email" />
          <TextInput source="contactPerson" label="Contact Person Name" />
        </FormTab>
        <style>
          form.tabbed-form div.ra-input label + div > table {`{
            width: 100% !important;
          }`}
        </style>
        <FormTab label="locations">
          <ReferenceManyField label="Locations" reference="location" target="partnerId" perPage="100">
            <Datagrid>
              <TextField source="id" />
              <TextField source="internalName" />
              <FormDataConsumer>
                {({ formData, record, basePath }) => (
                  <>
                    <EditButton basePath={basePath} record={record}/>
                    <Button
                      onClick={() => {
                        deleteLocationFromPartner(formData.id, record.id).then(() => window.location.reload());
                      }}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </FormDataConsumer>
            </Datagrid>
          </ReferenceManyField>
          <ReferenceArrayInput
            label="Add new locations"
            reference="location"
            source="newLocationsIds"
            allowEmpty
          >
            <AutocompleteArrayInput optionText={(record) => record.internalName} />
          </ReferenceArrayInput>
        </FormTab>
        <FormTab label="share settings">
          <BooleanInput source="useCustomShareConfig" label="Use Custom Settings" />
          <FormDataConsumer>
            {({ formData }) => (
              <>
                <NumberInput
                  source="sharePercent"
                  label="Share, %"
                  disabled={!formData.useCustomShareConfig}
                />
                <NumberInput
                  source="vatPercent"
                  label="VAT, %"
                  disabled={!formData.useCustomShareConfig}
                />
                <NumberInput
                  source="transactionFeePercent"
                  label="Transaction Fee, %"
                  disabled={!formData.useCustomShareConfig}
                />
                <NumberInput
                  source="newUserKickbackAmount"
                  label="New User Kickback"
                  parse={(v) => v * 100}
                  format={(v) => parseFloat(v) / 100}
                  disabled={!formData.useCustomShareConfig}
                />
                <NumberInput
                  source="newUserTimeout"
                  label="New User Timeout, min"
                  parse={(v) => v * 60000}
                  format={(v) => parseFloat(v) / 60000}
                  disabled={!formData.useCustomShareConfig}
                />
              </>
            )}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="balance">
          <Grid container direction="column" justify="space-around" alignItems="flex-start">
            <br />
            <div>
              <b>Gross revenue: </b>
              <span>{parseFloat(balance.grossRevenue) / 100}</span>
              &nbsp;
              <span>{balance.currency}</span>
            </div>
            <br />
            <div>
              <b>Net revenue: </b>
              <span>{parseFloat(balance.netRevenue) / 100}</span>
              &nbsp;
              <span>{balance.currency}</span>
            </div>
            <br />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                withdrawMoney(props.id).then(({ data }) => setBalance(data));
              }}
            >
              Withdraw
            </Button>
          </Grid>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

PartnersEdit.propTypes = {
  id: number,
};
