import instance from './instance';

const STATION_STATISTICS_BASE_URL = 'statistics';

export function getStationsStatus(from, to) {
  return instance.get(
    `${STATION_STATISTICS_BASE_URL}/stations/?from=${from.toISOString()}&to=${to.toISOString()}`,
  );
}

export function getScanAttempts(from, to) {
  return instance.get(
    `${STATION_STATISTICS_BASE_URL}/scans/?from=${from.toISOString()}&to=${to.toISOString()}`,
  );
}

export function getEmptyStationsCount(powerbanks = 3) {
  return instance.get(`${STATION_STATISTICS_BASE_URL}/stations/empty/?powerbanks=${powerbanks}`);
}
