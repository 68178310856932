import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showNotification, CardActions } from 'react-admin';
import { push } from 'react-router-redux';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { insertPowerbankToStation, ejectPowerbankFromStation } from '../../../api';

const StyledButton = withStyles({
  root: {
    marginRight: '10px',
  },
})(Button);

class PowerbankActionButtons extends React.Component {
  handleAction = async (action) => {
    const { data, pushToPath, showMsg } = this.props;
    const actions = {
      insert: {
        method: insertPowerbankToStation,
        msg: 'Powerbank inserted',
      },
      eject: {
        method: ejectPowerbankFromStation,
        msg: 'Powerbank ejected',
      },
    };

    if (!data.stationId) {
      return showMsg('Powerbank is not linked to a station', 'warning');
    }

    try {
      await actions[action].method(data.id, data.stationId);

      showMsg(actions[action].msg);
      pushToPath('/powerbank');
    } catch (error) {
      showMsg('There was an error', 'warning');
    }
  };

  render() {
    return (
      <CardActions>
        <StyledButton
          variant="contained"
          onClick={() => this.handleAction('insert')}
          color="primary"
        >
          Insert
        </StyledButton>
        <StyledButton
          variant="contained"
          onClick={() => this.handleAction('eject')}
          color="secondary"
        >
          Eject
        </StyledButton>
      </CardActions>
    );
  }
}

PowerbankActionButtons.propTypes = {
  data: PropTypes.shape(PropTypes.object),
  pushToPath: PropTypes.func.isRequired,
  showMsg: PropTypes.func,
  stationId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  stationId: state.form['record-form'] ? state.form['record-form'].values.stationId : null,
});

export default connect(mapStateToProps, {
  showMsg: showNotification,
  pushToPath: push,
})(PowerbankActionButtons);
