import React, { useState } from 'react';
import {
  List,
  Create,
  Edit,
  SimpleForm,
  Datagrid,
  EditButton,
  TextField,
  TextInput,
  NumberInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  ReferenceField,
  required,
} from 'react-admin';

const subscriptionConfigChoices = (paymentType) => {
  const choices = [
    {
      id: 'WEEKLY',
      name: 'WEEKLY',
    },
    {
      id: 'MONTHLY',
      name: 'MONTHLY',
    },
    {
      id: 'YEARLY',
      name: 'YEARLY',
    },
  ];

  if (paymentType === 'PROMO_SUBSCRIPTION') {
    return [
      {
        id: 'CUSTOM',
        name: 'CUSTOM',
      },
    ];
  }

  return choices;
};

const paymentTypeChoices = [
  {
    id: 'PAID_SUBSCRIPTION',
    name: 'PAID SUBSCRIPTION',
  },
  {
    id: 'PROMO_SUBSCRIPTION',
    name: 'PROMO SUBSCRIPTION',
  },
];

export const SubscriptionConfigList = (props) => (
  <List {...props} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid>
      <TextField label="Title" source="title" />
      <TextField label="Price" source="price" />
      <TextField label="Days" source="period" />
      <ReferenceField label="Country" reference="country" source="countryId">
        <TextField label="Country" source="name" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

export const SubscriptionConfigCreate = (props) => {
  const [consumerData, setConsumerData] = useState({});

  return (
    <Create {...props}>
      <SimpleForm>
        <FormDataConsumer>{({ formData }) => setConsumerData(formData)}</FormDataConsumer>

        <SelectInput
          label="Subscription Type"
          source="paymentType"
          choices={paymentTypeChoices}
          validate={[required()]}
        />
        <TextInput label="Title" source="title" validate={[required()]} />
        <NumberInput label="Price" source="price" validate={[required()]} />
        <ReferenceInput
          label="Country"
          reference="country"
          source="countryId"
          validate={[required()]}
        >
          <AutocompleteInput optionText={(record) => record.name} />
        </ReferenceInput>
        <SelectInput
          label="Duration"
          source="periodType"
          choices={subscriptionConfigChoices(consumerData.paymentType)}
          validate={[required()]}
        />
        {consumerData.periodType === 'CUSTOM' && (
          <NumberInput label="Amount of days" source="period" />
        )}
      </SimpleForm>
    </Create>
  );
};

export const SubscriptionConfigEdit = (props) => {
  const [consumerData, setConsumerData] = useState({});

  return (
    <Edit {...props}>
      <SimpleForm>
        <FormDataConsumer>{({ formData }) => setConsumerData(formData)}</FormDataConsumer>

        <SelectInput
          label="Subscription Type"
          source="paymentType"
          choices={paymentTypeChoices}
          validate={[required()]}
        />
        <TextInput label="Title" source="title" validate={[required()]} />
        <NumberInput label="Price" source="price" validate={[required()]} />
        <ReferenceInput
          label="Country"
          reference="country"
          source="countryId"
          validate={[required()]}
        >
          <AutocompleteInput optionText={(record) => record.name} />
        </ReferenceInput>
        <SelectInput
          label="Duration"
          source="periodType"
          choices={subscriptionConfigChoices(consumerData.paymentType)}
          validate={[required()]}
        />
        {consumerData.periodType === 'CUSTOM' && (
          <NumberInput label="Amount of days" source="period" />
        )}
      </SimpleForm>
    </Edit>
  );
};
