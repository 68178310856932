import React from 'react';
import {
  CardActions, Datagrid, DateField, List, RefreshButton, TextField,
} from 'react-admin';

const DiscountsActions = () => (
  <CardActions>
    <RefreshButton />
  </CardActions>
);

export default (props) => {
  return (
    <List {...props} actions={<DiscountsActions />} sort={{ field: 'id', order: 'ASC' }}>
      <Datagrid>
        <TextField source="id" />
        <DateField source="availableTill" showTime locales="en-GB" />
        <DateField source="claimedAt" showTime locales="en-GB" />
        <TextField source="state" />
        <TextField source="code" />
      </Datagrid>
    </List>
  );
};
