export const renderFieldUserInfo = (record) => {
  return record.user ? renderUserInfo(record.user) : '';
};

export const renderUserInfo = ({
  cellphone, email, firstname, lastname,
}) => {
  if (firstname && lastname) {
    return `${firstname} ${lastname}`;
  }

  if (email) {
    return email;
  }

  if (cellphone) {
    return cellphone;
  }

  return '';
};
