import { AttachMoney, Home } from '@material-ui/icons';
import { arrayOf, func } from 'prop-types';
import React, { createElement } from 'react';
import { getResources, MenuItemLink } from 'react-admin';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const capitalizeLabel = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

const Menu = ({ resources, onMenuClick }) => (
  <div>
    <MenuItemLink
      to="/"
      key=""
      primaryText="Dashboard"
      leftIcon={createElement(Home)}
      onClick={onMenuClick}
      exact
    />
    {resources.map(
      (resource) => resource.hasList && (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={capitalizeLabel(resource.options.label || resource.name)}
          label={resource.name}
          onClick={onMenuClick}
          leftIcon={createElement(resource.icon)}
        />
      ),
    )}
    <MenuItemLink
      to="/balance/create"
      primaryText="Add Balance"
      leftIcon={createElement(AttachMoney)}
      onClick={onMenuClick}
    />
  </div>
);

Menu.propTypes = {
  resources: arrayOf(Object).isRequired,
  onMenuClick: func.isRequired,
};

const mapStateToProps = (state) => ({
  resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(Menu));
