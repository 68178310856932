import { string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { getStationsStatus } from '../../api/statistics';
import { chartJSTimelineOptions } from '../../config/constants';

const StationsStatusChart = ({ fromDate, toDate }) => {
  const [data, setData] = useState({});

  const fetchData = async () => {
    const { data: response } = await getStationsStatus(new Date(fromDate), new Date(toDate));
    setData({
      datasets: [
        {
          label: 'Online Stations',
          fill: false,
          backgroundColor: 'rgba(0,168,0,1)',
          borderColor: 'rgba(0,168,0,1)',
          data: response.stationsOn.map((el) => ({ x: new Date(el.createdAt), y: el.value })),
        },
        {
          label: 'Offline Stations',
          fill: false,
          backgroundColor: 'rgba(168,0,0,1)',
          borderColor: 'rgba(168,0,0,1)',
          data: response.stationsOff.map((el) => ({ x: new Date(el.createdAt), y: el.value })),
        },
      ],
    });
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate]);

  return <Line data={data} options={chartJSTimelineOptions} />;
};

StationsStatusChart.propTypes = {
  fromDate: string,
  toDate: string,
};

export default StationsStatusChart;
