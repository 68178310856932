import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Map from './Map';
import SearchBox from './SearchBox';
import { getMarkers, getPosition } from './utils';

class GMap extends Component {
  state = {
    center: { lat: 0, lng: 0 },
    zoom: 3,
  }

  componentDidMount() {
    const { input } = this.props;
    const markers = getMarkers(input);
    if (markers) {
      if (markers instanceof Array) {
        this.setState({ center: markers[markers.length - 1] });
      } else {
        this.setState({ center: markers });
      }
    }
  }

  putMarker = ({ markerPos, input, multipleMarkers }) => {
    const currentValue = getMarkers(input);
    if (multipleMarkers) {
      if (currentValue) {
        return input.onChange([...currentValue, markerPos]);
      }
      return input.onChange([currentValue]);
    }
    return input.onChange(markerPos);
  }

  setCenter = markerPos => this.setState({ center: markerPos });

  putMarkerFromSearch = ({ markerPos, input, multipleMarkers }) => {
    this.putMarker({ markerPos, input, multipleMarkers });
    this.setCenter(markerPos);
  }

  deleteMarker = ({ markerPos, input, multipleMarkers }) => {
    const currentValue = getMarkers(input);
    let newValue;
    if (multipleMarkers) {
      newValue = currentValue.filter(mrk => mrk.lat !== markerPos.lat
        && mrk.lng !== markerPos.lng);
      if (!newValue.length) {
        newValue = '';
      }
    } else { newValue = ''; }
    input.onChange(newValue);
  }

  render() {
    const {
      googleKey, input, multipleMarkers, searchable,
    } = this.props;
    const { center, zoom } = this.state;
    const childrenProps = {
      input,
      markers: getMarkers(input),
      multipleMarkers,
      loadingElement: <div style={{ height: '100%' }} />,
      containerElement: <div style={{ height: '500px' }} />,
      googleMapURL:
        `https://maps.googleapis.com/maps/api/js?key=${googleKey}&v=3.exp&libraries=geometry,drawing,places`,
    };

    return (
      <>
        <div style={{ position: 'relative' }}>
          {!!searchable
            && (
              <SearchBox
                putMarker={this.putMarkerFromSearch}
                initialPlaceId={this.props.initialPlaceId}
                setGooglePlace={this.props.setGooglePlace}
                deleteMarker={this.deleteMarker}
                {...childrenProps}
              />
            )
          }
          <Map
            mapElement={<div style={{ height: '100%' }} />}
            center={center}
            defaultZoom={zoom}
            onMapClick={e => this.putMarker({
              input,
              multipleMarkers,
              markerPos: getPosition(e),
            })}
            onMarkerClick={e => this.deleteMarker({
              input,
              multipleMarkers,
              markerPos: getPosition(e),
            })}
            {...childrenProps}
          />
        </div>
      </>
    );
  }
}

GMap.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
  }),
  multipleMarkers: PropTypes.bool,
  searchable: PropTypes.bool,
  googleKey: PropTypes.string,
  source: PropTypes.string,
  placeId: PropTypes.string.isRequired,
  setGooglePlace: PropTypes.func.isRequired,
};


const WithFieldGmap = ({ source, ...props }) => <Field name={source} component={GMap} {...props} />;

WithFieldGmap.propTypes = {
  source: PropTypes.string,
};

export default WithFieldGmap;
