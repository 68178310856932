import PropTypes, { number } from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {
  CardActions,
  Create,
  CreateButton,
  Datagrid,
  Edit,
  EditButton,
  List,
  RefreshButton,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceInput,
  ReferenceField,
  SelectInput,
  FileInput,
  FileField,
} from 'react-admin';

const VoucherActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </CardActions>
);

VoucherActions.propTypes = {
  basePath: PropTypes.string,
};

export const VoucherList = (props) => {
  return (
    <List {...props} actions={<VoucherActions />} sort={{ field: 'id', order: 'ASC' }}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="code" label="code" />
        <TextField source="barCode" label="barCode" />
        <ReferenceField source="voucherId" reference="campaign">
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const VoucherCreate = (props) => {
  //TODO WIP
  const [dataFromFile, setDataFromFile] = useState('');

  console.log(dataFromFile);

  const parseFile = useCallback((file) => {
    const fileReader = new FileReader();
    fileReader.readAsText(file.rawFile)

    fileReader.onload = function() {
      const error = fileReader.error;
      const text = fileReader.result;

      console.log(error);
      console.log(typeof text);

      setDataFromFile(text);

      console.log(dataFromFile);
    }

    return file;
  }, []);

  return (
    <Create {...props}>
      <SimpleForm>
        <FileInput source="file"
                   label="Related files"
                   parse={parseFile}
        >
          <FileField source="src" title="title"
          />
        </FileInput>
        <ReferenceInput label="Campaign" source="campaign.id" reference="campaign">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <TextInput multiline source="dataFromFile"/>
      </SimpleForm>
    </Create>
  );
};

export const VoucherEdit = (props) => {
  return (
    <Edit {...props}>
      //TODO WIP
      {/*<SimpleForm>*/}
      {/*  <TextInput source="name" label="name" />*/}
      {/*  <TextInput source="smallBannerUrl" label="smallBannerUrl" />*/}
      {/*  <TextInput source="detailsBannerUrl" label="detailsBannerUrl" />*/}
      {/*  <TextInput source="redeemBannerUrl" label="redeemBannerUrl" />*/}
      {/*  <TextInput source="codeBannerUrl" label="codeBannerUrl" />*/}
      {/*  <ReferenceInput label="Campaign" source="campaign.id" reference="campaign">*/}
      {/*    <SelectInput optionText="title" />*/}
      {/*  </ReferenceInput>*/}
      {/*  <ArrayInput source="conditions">*/}
      {/*    <SimpleFormIterator>*/}
      {/*      <TextInput source="city" />*/}
      {/*      <TextInput source="country" />*/}
      {/*      <TextInput source="startLocation" />*/}
      {/*      <TextInput source="endLocation" />*/}
      {/*      <TextInput source="minimumDuration" />*/}
      {/*    </SimpleFormIterator>*/}
      {/*  </ArrayInput>*/}
      {/*</SimpleForm>*/}
    </Edit>
  );
};

VoucherEdit.propTypes = {
  id: number,
};
