import firebase from 'firebase';

export const firebaseConfig = {
  apiKey: 'AIzaSyCZIjrjRSVyuWSIAUz0wCriw7GrwcI_D2s',
  authDomain: 'lectogo.firebaseapp.com',
  databaseURL: 'https://lectogo.firebaseio.com',
  projectId: 'lectogo',
  storageBucket: 'lectogo.appspot.com',
  messagingSenderId: '287504444666',
};

firebase.initializeApp(firebaseConfig);

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const firebaseAuth = firebase.auth;

export const PromocodeTypeBalance = 'BALANCE';
export const PromocodeTypeLease = 'LEASE';
export const PromocodeTypeSubscription = 'SUBSCRIPTION';
export const PromocodeTypeDayPass = 'DAYPASS';
export const PromotionTypes = [
  {
    id: 'FREE_HOUR',
    name: 'Free hour',
  },
  {
    id: 'FREE_HALF_HOUR',
    name: 'Free 30 minutes',
  },
];

export const PowerbankStatuses = [
  'STATION',
  'USER',
];

export const LeaseStatuses = [
  'RELEASE_FAILED',
  'RETURNED',
  'PENDING_PAYMENT',
  'PAYMENT_FAILED',
  'FULFILLED',
  'STOLEN',
  'PAID',
  'REQUIRES_ADDITIONAL_STEPS',
];

export const NotificationTypes = [
  'POWERBANK_STOLEN',
  'RELEASE_FAILED',
  'STILL_CHARGING',
  'POWERBANK_RETURNED',
  'AMOUNT_REFUNDED',
  'CARD_EXPIRED',
  'CARD_INSUFFICIENT_FUNDS',
  'POWERBANK_LOW',
  'REPAID_SUCCESSFULLY',
];

export const EventTypes = [
  'SCAN_ONLINE_STATION',
  'SCAN_OFFLINE_STATION',
  'MSG_SENT_TO_CONNECTOR',
  'MSG_RECEIVED_ON_CONNECTOR',
  'MSG_SENT_TO_STATION',
  'MSG_RECEIVED_ON_API',
];

export const AppTypes = [
  'WEB_APP',
  'ANDROID_APP',
  'IOS_APP',
];

export const chartJSTimelineOptions = {
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    xAxes: [
      {
        type: 'time',
        time: {
          format: 'HH:mm',
          unit: 'hour',
          unitStepSize: 2,
          displayFormats: {
            minute: 'HH:mm',
            hour: 'HH:mm',
          },
          tooltipFormat: 'HH:mm',
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

export const chartHistory = {
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    xAxes: [
      {
        barThickness: 10,
        type: 'time',
        time: {
          format: 'D/MM',
          unit: 'hour',
          unitStepSize: 1,
          displayFormats: {
            minute: 'D/MM',
            hour: 'D/MM',
          },
          tooltipFormat: 'D/MM/y HH:mm',
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
};
