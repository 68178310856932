import React from 'react';
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  RefreshButton,
  SelectInput,
  TextField,
} from 'react-admin';
import { NotificationTypes } from '../../config/constants';
import { renderUserInfo } from '../../utils/users';

const NotificationFilters = (props) => {
  const now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  const lastWeek = new Date(now.getTime());
  const last2Weeks = new Date(now.getTime());
  const lastMonth = new Date(now.getTime());
  const last3Months = new Date(now.getTime());
  const last6Months = new Date(now.getTime());
  const lastYear = new Date(now.getTime());

  lastWeek.setDate(now.getDate() - 7);
  last2Weeks.setDate(now.getDate() - 14);
  lastMonth.setMonth(now.getMonth() - 1);
  last3Months.setMonth(now.getMonth() - 3);
  last6Months.setMonth(now.getMonth() - 6);
  lastYear.setFullYear(now.getFullYear() - 1);

  return (
    <Filter {...props}>
      <SelectInput
        source="createdAt"
        label="Time span"
        alwaysOn
        resettable
        choices={[
          { id: lastWeek.toISOString(), name: 'Last 1 week' },
          { id: last2Weeks.toISOString(), name: 'Last 2 weeks' },
          { id: lastMonth.toISOString(), name: 'Last 1 month' },
          { id: last3Months.toISOString(), name: 'Last 3 months' },
          { id: last6Months.toISOString(), name: 'Last 6 months' },
          { id: lastYear.toISOString(), name: 'Last 1 year' },
        ]}
      />

      <ReferenceInput label="User" source="userId" reference="user" alwaysOn perPage={50}>
        <AutocompleteInput optionText={renderUserInfo} optionValue="id" />
      </ReferenceInput>

      <SelectInput
        source="type"
        alwaysOn
        resettable
        choices={NotificationTypes.map((status) => ({
          id: status,
          name: status,
        }))}
      />
    </Filter>
  );
};

const NotificationsList = (props) => (
  <List
    {...props}
    title="Notifications"
    sort={{ field: 'id', order: 'DESC' }}
    actions={<RefreshButton />}
    filters={<NotificationFilters />}
  >
    <Datagrid>
      <DateField source="createdAt" showTime locales="en-GB" />
      <ReferenceField label="User" source="userId" reference="user">
        <FunctionField render={renderUserInfo} />
      </ReferenceField>
      <TextField source="type" />
      <TextField source="message.notification.title" label="Title" />
      <TextField source="message.notification.body" label="Message" />
    </Datagrid>
  </List>
);

export default NotificationsList;
