import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  Pagination,
  DateField,
} from 'react-admin';
import {Grid} from "@material-ui/core";
import StationsStatusChart from "../dashboard/StationsStatusChart";
import ScanAttemptStats from "../dashboard/ScanAttemptStats";
import Badge from "../dashboard/Badge";
import {STATION_FILTERED_URL} from "../../api/station";
import {getEmptyStationsCount} from "../../api/statistics";
import { useEffect, useState } from 'react';

const StationHistoryPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500]} {...props} />
);

export const StationHistoryList = (props) => {
  const then = new Date();
  const now = new Date();

  then.setDate(new Date().getDate() - 1);
  now.setDate(new Date().getDate() + 1);

  const [fromDate, setFromDate] = useState(then.toISOString().substr(0, 10));
  const [toDate, setToDate] = useState(now.toISOString().substr(0, 10));
  const [emptyStationsCount, setEmptyStationsCount] = useState(0);

  useEffect(() => {
    const powerbanksLimit = 3;
    getEmptyStationsCount(powerbanksLimit)
      .then(({data: response}) => setEmptyStationsCount(response));
  }, []);
  return (
    <>
    <Grid container>
      <Grid item xs={12}>
        <label htmlFor="fromDate" style={{marginRight: '15px'}}>
          <b>From: </b>
          <input
            id="fromDate"
            type="date"
            onChange={(e) => setFromDate(e.target.value)}
            value={fromDate}
          />
        </label>
        <label htmlFor="toDate">
          <b>To: </b>
          <input
            id="toDate"
            type="date"
            onChange={(e) => setToDate(e.target.value)}
            value={toDate}
          />
        </label>
      </Grid>
      <Grid alignContent="center" item md={6} xs={12}>
        <h3>Stations Status</h3>
        <StationsStatusChart fromDate={fromDate} toDate={toDate}/>
      </Grid>
      <Grid alignContent="center" item md={6} xs={12}>
        <h3>Scan Attempts</h3>
        <ScanAttemptStats fromDate={fromDate} toDate={toDate}/>
      </Grid>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <h3>Stations statistics</h3>
        </Grid>
        <Grid item md={1} xs={12} alignContent="center">
          <Badge title="Few powerbanks" value={emptyStationsCount} link={STATION_FILTERED_URL}/>
        </Grid>
      </Grid>
    </Grid>
    {/*<List*/}
    {/*  {...props}*/}
    {/*  sort={{field: 'id', order: 'ASC'}}*/}
    {/*  pagination={<StationHistoryPagination/>}*/}
    {/*>*/}
    {/*  <Datagrid>*/}
    {/*    <TextField source="id"/>*/}
    {/*    <DateField source="createdAt"/>*/}
    {/*    <TextField source="status"/>*/}
    {/*    <ReferenceField label="Station" source="id" reference="station">*/}
    {/*      <TextField source="hardwareId"/>*/}
    {/*    </ReferenceField>*/}
    {/*    <EditButton/>*/}
    {/*  </Datagrid>*/}

    {/*</List>*/}

    </>
  )
};
