import instance from './instance';

const POWERBANK_BASE_URL = '/powerbank';

export function insertPowerbankToStation(powerbankId, stationId) {
  return instance.patch(`${POWERBANK_BASE_URL}/${powerbankId}/station`, { stationId });
}

export function ejectPowerbankFromStation(powerbankId) {
  return instance.delete(`${POWERBANK_BASE_URL}/${powerbankId}/station`);
}
