import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_GET_PERMISSIONS, AUTH_ERROR } from 'react-admin';
import firebase from 'firebase/app';
import { firebaseAuth, googleProvider, firebaseConfig } from './constants';
import instance from '../api/instance';

const baseConfig = {
  userProfilePath: '/users/',
  userAdminProp: 'isAdmin',
  localStorageTokenName: 'lectogo',
  handleAuthStateChange: async (auth, baseConf) => {
    if (auth) {
      const snapshot = await firebase
        .database()
        .ref(baseConf.userProfilePath + auth.uid)
        .once('value');
      const profile = snapshot.val();
      if (profile && profile[baseConf.userAdminProp]) {
        const firebaseToken = auth.getIdToken();
        const user = { auth, profile, firebaseToken };
        localStorage.setItem(baseConf.localStorageTokenName, firebaseToken);
        return user;
      }
      firebase.auth().signOut();
      localStorage.removeItem(baseConf.localStorageTokenName);
      throw new Error('sign_in_error');
    } else {
      localStorage.removeItem(baseConf.localStorageTokenName);
      throw new Error('sign_in_error');
    }
  },
};

export default async (type, params) => {
  if (type === AUTH_LOGOUT) {
    baseConfig.handleAuthStateChange(null, baseConfig).catch(() => {});
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    return logout();
  }

  if (firebase.auth().currentUser) {
    await firebase.auth().currentUser.reload();
  }

  if (type === AUTH_CHECK) {
    const newTokenRequest = await fetch(
      `https://securetoken.googleapis.com/v1/token?key=${firebaseConfig.apiKey}`,
      {
        method: 'post',
        body: JSON.stringify({
          grant_type: 'refresh_token',
          refresh_token: localStorage.getItem('refreshToken'),
        }),
      },
    );
    const result = await newTokenRequest.json();
    localStorage.setItem('authToken', result.id_token);
    localStorage.setItem('refreshToken', result.refresh_token);
    const firebaseLoaded = () =>
      new Promise((resolve) => {
        firebase.auth().onAuthStateChanged(resolve);
      });
    await firebaseLoaded();

    if (!firebase.auth().currentUser || !localStorage.getItem('authToken')) {
      throw new Error('sign_in_error');
    }

    return true;
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem('role');
    return role ? Promise.resolve(role) : Promise.reject();
  }

  if (type === AUTH_LOGIN) {
    return loginWithGoogle()
      .then((result) => {
        if (
          result.additionalUserInfo.profile.hd !== 'fusionworks.md' &&
          result.additionalUserInfo.profile.hd !== 'lectogo.com'
        ) {
          return logout();
        }
        firebase
          .auth()
          .currentUser.getIdToken()
          .then((token) => {
            localStorage.setItem('refreshToken', result.user.refreshToken);
            localStorage.setItem('authToken', token);
            Object.assign(instance.defaults, { headers: { Authorization: `Bearer ${token}` } });
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401 || status === 403) {
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken || refreshToken === 'undefined') {
        return Promise.reject();
      }

      window.location.reload();
    }

    return Promise.resolve();
  }

  return Promise.reject(new Error('Unknown method'));
};

export function loginWithGoogle() {
  return firebaseAuth().signInWithPopup(googleProvider);
}

export function logout() {
  return firebaseAuth().signOut();
}
