import React, { Component } from 'react';
import firebase from 'firebase';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import './settings.css';

class ImageInputToFirebase extends Component {
  constructor(props) {
    super(props);
    const { input } = this.props;
    const { value } = input;
    this.state = {
      imgName: '',
      progress: 0,
      isUploading: false,
      imgUrl: value || '',
    };
  }

  handleUploadStart = (e) => {
    this.setState({ isUploading: true, progress: 0 })
  };

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = filename => {
    const { input } = this.props;
    const { onChange } = input;
    firebase
      .storage()
      .ref('images')
      .child(filename)
      .getDownloadURL()
      .then(url => {
        this.setState(() => {
          onChange(url);
          return {
            imgUrl: url,
            imgName: filename,
            progress: 100,
            isUploading: false
          };
        })
      })
      .catch(e => { console.log(e) });
  };

  renderButtonText() {
    let btnText;
    const { isUploading, progress, imgUrl } = this.state;
    if (isUploading) {
      btnText = `Loading ${progress}%`;
    } else {
      btnText = (imgUrl) ? 'Select another image' : 'Select your Image';
    }

    return btnText;
  }

  render() {
    const { isUploading, imgUrl, imgName } = this.state;
    return (
      <div>
        <CustomUploadButton
          accept="image/*"
          storageRef={firebase.storage().ref('images')}
          onUploadStart={this.handleUploadStart}
          onUploadError={this.handleUploadError}
          onUploadSuccess={this.handleUploadSuccess}
          onProgress={this.handleProgress}
          className="btn"
          randomizeFilename
        >
          {this.renderButtonText()}
        </CustomUploadButton>
        <span style={{ marginBottom: 20 }}>
          {imgName || 'No image selected'}
        </span>
        {(imgUrl && !isUploading) && (
          <div>
            <img src={imgUrl} alt="location-background" />
          </div>
        )}
      </div>
    );
  }
}

ImageInputToFirebase.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),
};

const imgToFirebase = () => <Field name="cover" component={ImageInputToFirebase} />;
export default imgToFirebase;
