import React from 'react';
import { ExportButton, CardActions, downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import getExportData from '../../api/lease';

const ExportAction = ({ currentSort, filterValues, resource, total }) => {
  const exportCsv = async (filters) => {
    const { data } = await getExportData(filters);
    const csv = convertToCSV({ data });

    downloadCSV(csv, 'leases');
  };

  return (
    <CardActions>
      <ExportButton
        disabled={!total}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={false}
        label="Export"
        onClick={() => exportCsv(filterValues)}
      />
    </CardActions>
  );
};

export default ExportAction;
