import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import {
  withGoogleMap,
  withScriptjs,
  Marker,
  GoogleMap,
} from 'react-google-maps';
import config from '../config';

class LocationsMapComponent extends React.PureComponent {
  state = {
    list: [],
  };

  componentDidMount() {
    const { endpoint } = this.props;
    axios
      .get(`${config.api}/${endpoint}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      })
      .then(res => [
        this.setState({
          list: res.data,
        }),
      ])
      .catch(err => console.log(err));
  }

  handleMarkerRedirect = id => {
    const { endpoint, pushMrk } = this.props;
    pushMrk(`/${endpoint}/${id}`);
  };

  render() {
    const defaultCenter = { lat: 59.34, lng: 18.072 };
    const { list } = this.state;
    return (
      <React.Fragment>
        <GoogleMap
          defaultZoom={7}
          defaultCenter={defaultCenter}
        >
          {list.map((marker, i) => {
            const position = {
              lat: parseFloat(marker.coordinates.lat),
              lng: parseFloat(marker.coordinates.lng),
            };
            return (
              <Marker
                key={marker.id}
                position={position}
                title={marker.venue_name}
                onClick={e => this.handleMarkerRedirect(marker.id)}
              />
            );
          })}
        </GoogleMap>
      </React.Fragment>
    );
  }
}

LocationsMapComponent.propTypes = {
  endpoint: PropTypes.string,
  pushMrk: PropTypes.func,
};

const LocationsMap = connect(
  null,
  { pushMrk: push },
)(withScriptjs(withGoogleMap(LocationsMapComponent)));

export default LocationsMap;
