
const schedule = {
  timezone: '+0100',
  monday: {
    start: '9:00',
    end: '18:00',
    isWorking: true,
  },
  tuesday: {
    start: '9:00',
    end: '18:00',
    isWorking: true,
  },
  wednesday: {
    start: '9:00',
    end: '18:00',
    isWorking: true,
  },
  thursday: {
    start: '9:00',
    end: '18:00',
    isWorking: true,
  },
  friday: {
    start: '9:00',
    end: '18:00',
    isWorking: true,
  },
  saturday: {
    start: '9:00',
    end: '18:00',
    isWorking: true,
  },
  sunday: {
    start: '9:00',
    end: '18:00',
    isWorking: true,
  },
};

export default JSON.stringify(schedule);
