import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from 'material-ui-pickers';
import Checkbox from '@material-ui/core/Checkbox';
import TimezonePicker from 'react-timezone';
import { Field } from 'redux-form';
import { Button } from '@material-ui/core';
import scheduleDefault from '../../utils/schedule.utils';
import './jsonInput.css';

const Day = ({
  day, data, onChange, checkboxChange, disabled,
}) => {

  const [openHours, openMinutes] = data.start.split(':');
  const [closeHours, closeMinutes] = data.end.split(':');
  const openDate = new Date();
  const closeDate = new Date();
  openDate.setHours(Number(openHours));
  openDate.setMinutes(Number(openMinutes));
  closeDate.setHours(Number(closeHours));
  closeDate.setMinutes(Number(closeMinutes));

  return (
    <div className="day">
      <h3>{day}</h3>
      <div>
        <Checkbox
          disabled={disabled}
          checked={data.isWorking || disabled}
          onChange={(e) => checkboxChange(e, day)}
          value="isWorking"
          color="primary"
        />
        <TimePicker
          keyboard
          ampm={false}
          disabled={!data.isWorking || disabled}
          mask={[/\d/, /\d/, ':', /\d/, /\d/]}
          value={openDate}
          onChange={(e) => onChange(e, day, 'start')}
          disableOpenOnEnter
        />

        <TimePicker
          keyboard
          ampm={false}
          disabled={!data.isWorking || disabled}
          value={closeDate}
          onChange={(e) => onChange(e, day, 'end')}
          mask={[/\d/, /\d/, ':', /\d/, /\d/]}
          disableOpenOnEnter
        />
      </div>
    </div>
  );
};

Day.propTypes = {
  day: PropTypes.string,
  onChange: PropTypes.func,
  checkboxChange: PropTypes.func,
  data: PropTypes.shape({
    isWorking: PropTypes.bool,
    start: PropTypes.string,
    end: PropTypes.string,
  }),
};

class JSONHandler extends Component {
  constructor(props) {
    super(props);
    let fullTime = true;
    const scheduleState = props.record.schedule || JSON.parse(scheduleDefault);
    const obj = scheduleState;
    Object.keys(obj).forEach((key) => {
      if (key !== 'timezone') {
        if (obj[key].end !== '00:00' || obj[key].start !== '00:00') {
          fullTime = false;
        }
      }
    });
    this.state = {
      jsonVal: JSON.stringify(scheduleState),
      fullTime,
      obj: { ...scheduleState },
    };
  }

  componentDidMount() {
    const { input } = this.props;
    const { onChange } = input;
    const { jsonVal } = this.state;
    onChange(JSON.parse(jsonVal));
  }

  onChange = (e, itm, lim) => {
    if (!this.state.fullTime) {
      let { jsonVal } = this.state;
      const { obj } = this.state;
      const { input } = this.props;
      const { onChange } = input;

      this.setState(() => {
        obj[itm][lim] = e.format('HH:mm');
        jsonVal = JSON.stringify(obj);
        onChange(obj);
        return { obj, jsonVal };
      });
    }
  };

  checkboxChange = (e, day) => {
    let { jsonVal } = this.state;
    const { obj } = this.state;
    const { input } = this.props;
    const { onChange } = input;
    obj[day].isWorking = !obj[day].isWorking;
    jsonVal = JSON.stringify(obj);
    onChange(obj);
    this.setState({
      jsonVal,
      obj,
    });
  };

  selectTimezone(timezone) {
    let { jsonVal } = this.state;
    const { obj } = this.state;
    const { input } = this.props;
    const { onChange } = input;
    this.setState(() => {
      obj.timezone = timezone;
      jsonVal = JSON.stringify(obj);
      onChange(obj);
      return { obj, jsonVal };
    });
  }

  formatTime = (time) => {
    return `${time.slice(0, 2)}:${time.slice(2)}`;
  };

  parseGMapSchedule = () => {
    const { googleSchedule } = this.props;

    if (googleSchedule && !googleSchedule.length) {
      return;
    }

    const orderedDayList = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ];

    const parsed = {
      timezone: this.state.obj.timezone,
      monday: { start: '00:00', end: '00:00', isWorking: false },
      tuesday: { start: '00:00', end: '00:00', isWorking: false },
      wednesday: { start: '00:00', end: '00:00', isWorking: false },
      thursday: { start: '00:00', end: '00:00', isWorking: false },
      friday: { start: '00:00', end: '00:00', isWorking: false },
      saturday: { start: '00:00', end: '00:00', isWorking: false },
      sunday: { start: '00:00', end: '00:00', isWorking: false },
    };

    googleSchedule.forEach((el) => {
      parsed[orderedDayList[el.open.day]].end = this.formatTime(el.close.time);
      parsed[orderedDayList[el.open.day]].start = this.formatTime(el.open.time);
      parsed[orderedDayList[el.open.day]].isWorking = true;
    });

    this.setState({
      jsonVal: JSON.stringify(parsed),
      obj: parsed,
    });
    this.props.input.onChange(parsed);
  };

  renderInputs = () => {
    const orderedDayList = [
      'timezone',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];
    const { obj } = this.state;
    const result = [];
    orderedDayList.map((itm) => {
      if (itm !== 'timezone') {
        result.push(
          <Day
            disabled={this.state.fullTime}
            key={itm}
            day={itm}
            data={obj[itm]}
            onChange={this.onChange}
            checkboxChange={this.checkboxChange}
          />,
        );
      } else {
        result.push(
          <TimezonePicker
            value={obj[itm] || 'Asia/Yerevan'}
            onChange={(timezone) => this.selectTimezone(timezone)}
            key={itm}
            inputProps={{
              disabled: this.state.fullTime,
              placeholder: 'Select Timezone...',
              name: 'timezone',
            }}
            className="timezone-picker"
          />,
        );
      }

      return itm;
    });

    return result;
  };

  changeFullTime = (e) => {
    const { input } = this.props;
    const { onChange } = input;
    const { obj } = this.state;
    let { jsonVal } = this.state;
    Object.keys(obj).forEach((key) => {
      if (key !== 'timezone') {
        obj[key].end = '00:00';
        obj[key].start = '00:00';
        obj[key].isWorking = true;
      }
    });

    jsonVal = JSON.stringify(obj);
    onChange(obj);
    this.setState({ jsonVal, obj, fullTime: !this.state.fullTime });
  };

  render() {
    return (
      <div style={{ marginBottom: 25 }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <h2 style={{ marginRight: '10px' }}>Schedule</h2>
          <Button onClick={this.parseGMapSchedule}>Load schedule from Google</Button>
        </div>
        <div className="day" style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <h3>24/7</h3>

          <Checkbox
            style={{ marginTop: '7px' }}
            checked={this.state.fullTime}
            onChange={this.changeFullTime}
            color="primary"
          />
        </div>
        {this.renderInputs()}
      </div>
    );
  }
}

JSONHandler.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.object,
    onChange: PropTypes.func,
  }),
  googleSchedule: PropTypes.array,
};

const JSONInput = (props) => <Field name="schedule" component={JSONHandler} {...props} />;

export default JSONInput;
