import { Button, Grid, TextField as TextFieldV2 } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  AutocompleteInput,
  CardActions,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  FormTab,
  FunctionField,
  ExportButton,
  List,
  LongTextInput,
  NumberField,
  NumberInput,
  ReferenceInput,
  ReferenceField,
  ReferenceManyField,
  RefreshButton,
  SelectField,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin';
import { ejectPowerbankFromStation } from '../../api';
import { ejectBySlot } from '../../api/station';
import StationsHistoryChart from '../stationHistory/history-chart/StationsHistoryChart';

const StationActions = ({
  basePath,
  currentSort,
  filterValues,
  resource,
  total,
}) => {
  return (
    <CardActions>
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={!total}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={false}
        label="Export"
      />
      <RefreshButton />
    </CardActions>
  );
};

StationActions.propTypes = {
  basePath: PropTypes.string,
  currentSort: PropTypes.instanceOf(Object),
  filterValues: PropTypes.instanceOf(Object),
  resource: PropTypes.string,
  total: PropTypes.number,
};

const StationFilters = (props) => {
  return (
    <Filter {...props}>
      <TextInput alwaysOn source="hardwareId" label="Hardware ID" />
      <SelectInput
        alwaysOn
        source="status"
        resettable
        choices={[
          { id: 'ON', name: 'On' },
          { id: 'OFF', name: 'Off' },
        ]}
      />
      <NumberInput alwaysOn source="available" />
      <NumberInput alwaysOn source="capacity" />
      <NumberInput alwaysOn source="availableLessThan" label="Less than" />
      <NumberInput alwaysOn source="availableMoreThan" label="More than" />
      <TextInput alwaysOn source="country" label="Country" />
    </Filter>
  );
};

export const StationList = (props) => (
  <List
    {...props}
    sort={{ field: 'id', order: 'ASC' }}
    actions={<StationActions />}
    filters={<StationFilters />}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="hardwareId" label="Hardware ID" />
      <NumberField source="available" />
      <NumberField source="capacity" />
      <ReferenceField label="Price" reference="price" source="priceId" allowEmpty>
        <FunctionField
          label="Price"
          render={(record) => `${record.rateFee / 100} ${record.currency}`}
        />
      </ReferenceField>
      <ReferenceField label="Location" source="locationId" reference="location">
        <TextField source="internalName" />
      </ReferenceField>
      <SelectField
        source="status"
        choices={[
          { id: 'ON', name: 'ON' },
          { id: 'OFF', name: 'OFF' },
        ]}
      />
      <DateField source="updatedAt" showTime locales="en-GB" />
      <EditButton />
    </Datagrid>
  </List>
);

export const StationCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="hardwareId" label="Hardware ID" />
        <NumberInput source="capacity" />
        <ReferenceInput label="Add price" reference="price" source="priceId" allowEmpty>
          <AutocompleteInput optionText={(record) => record.name} />
        </ReferenceInput>
        <ReferenceInput
          label="Stations rack"
          reference="stations-rack"
          source="stationsRackId"
          allowEmpty
        >
          <AutocompleteInput
            optionText={(record) => {
              return record.hardwareId;
            }}
          />
        </ReferenceInput>
        <SelectInput
          source="status"
          label="Status"
          choices={[
            {
              id: 'ON',
              name: 'ON',
            },
            {
              id: 'OFF',
              name: 'OFF',
            },
          ]}
        />
        <SelectInput
          source="serviceStatus"
          allowEmpty
          choices={[
            { id: 'WAREHOUSE', name: 'WAREHOUSE' },
            { id: 'TERRITORY', name: 'TERRITORY' },
            { id: 'SERVICE', name: 'SERVICE' },
          ]}
        />
        <ReferenceInput label="Location" source="locationId" reference="location">
          <AutocompleteInput optionText="internalName" optionValue="id" />
        </ReferenceInput>
        <LongTextInput source="certificate" label="Certificate" />
      </SimpleForm>
    </Create>
  );
};

const StationTitle = ({ record }) => <span>{record ? `Station ${record.hardwareId}` : ''}</span>;

StationTitle.propTypes = {
  record: PropTypes.shape({
    hardwareId: PropTypes.string,
  }),
};

const styles = {
  table: {
    width: '250% !important',
  },
};

const StationHistoryFilter = (props) => {
  const now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  const lastWeek = new Date(now.getTime());
  const last2Weeks = new Date(now.getTime());
  const lastMonth = new Date(now.getTime());
  const last3Months = new Date(now.getTime());
  const last6Months = new Date(now.getTime());
  const lastYear = new Date(now.getTime());

  lastWeek.setDate(now.getDate() - 7);
  last2Weeks.setDate(now.getDate() - 14);
  lastMonth.setMonth(now.getMonth() - 1);
  last3Months.setMonth(now.getMonth() - 3);
  last6Months.setMonth(now.getMonth() - 6);
  lastYear.setFullYear(now.getFullYear() - 1);

  return (
    <Filter {...props}>
      <SelectInput
        source="createdAt"
        label="Time span"
        alwaysOn
        resettable
        choices={[
          { id: lastWeek.toISOString(), name: 'Last 1 week' },
          { id: last2Weeks.toISOString(), name: 'Last 2 weeks' },
          { id: lastMonth.toISOString(), name: 'Last 1 month' },
          { id: last3Months.toISOString(), name: 'Last 3 months' },
          { id: last6Months.toISOString(), name: 'Last 6 months' },
          { id: lastYear.toISOString(), name: 'Last 1 year' },
        ]}
      />
    </Filter>
  );
};
export const StationEdit = withStyles(styles)(({ classes, ...props }) => {
  const [slotNumber, setSlotNumber] = React.useState('');

  const then = new Date();
  const now = new Date();

  then.setDate(new Date().getDate() - 1);
  now.setDate(new Date().getDate() + 1);

  const [fromDate, setFromDate] = useState(then.toISOString().substr(0, 10));
  const [toDate, setToDate] = useState(now.toISOString().substr(0, 10));
  const [stationId, setStationId] = useState(null);

  return (
    <Edit title={<StationTitle />} {...props}>
      <TabbedForm>
        <FormTab label="station">
          <TextInput source="hardwareId" />
          <NumberInput source="capacity" />
          <ReferenceInput label="Add price" reference="price" source="priceId" allowEmpty>
            <AutocompleteInput
              optionText={(record) => {
                return record.name;
              }}
            />
          </ReferenceInput>
          <ReferenceInput
            label="Stations rack"
            reference="stations-rack"
            source="stationsRackId"
            allowEmpty
          >
            <AutocompleteInput
              optionText={(record) => {
                return record.hardwareId;
              }}
            />
          </ReferenceInput>
          <SelectInput
            source="status"
            choices={[
              { id: 'ON', name: 'ON' },
              { id: 'OFF', name: 'OFF' },
            ]}
          />
          <SelectInput
            source="serviceStatus"
            allowEmpty
            choices={[
              { id: 'WAREHOUSE', name: 'WAREHOUSE' },
              { id: 'TERRITORY', name: 'TERRITORY' },
              { id: 'SERVICE', name: 'SERVICE' },
            ]}
          />
          <ReferenceInput label="Location" source="locationId" reference="location">
            <AutocompleteInput optionText="internalName" optionValue="id" />
          </ReferenceInput>
          <LongTextInput source="certificate" label="Certificate" />
        </FormTab>
        <FormTab label="attached powebanks">
          <ReferenceManyField label="Powerbanks" reference="powerbank" target="stationId">
            <Datagrid>
              <ReferenceField label="Hardware ID" source="id" reference="powerbank">
                <TextField source="hardwareId" />
              </ReferenceField>

              <TextField source="status" />
              <TextField source="chargeLevel" />
              <FormDataConsumer>
                {({ record }) => {
                  return (
                    <Button
                      variant="contained"
                      onClick={() => {
                        ejectPowerbankFromStation(record.id);
                      }}
                      color="secondary"
                      style={{ marginRight: '15px' }}
                    >
                      Eject
                    </Button>
                  );
                }}
              </FormDataConsumer>
            </Datagrid>
          </ReferenceManyField>
          <FormDataConsumer>
            {({ record }) => (
              <form noValidate autoComplete="off">
                <Button
                  variant="contained"
                  onClick={() => {
                    ejectBySlot(record.id, slotNumber);
                    setSlotNumber('');
                  }}
                  color="secondary"
                  style={{ marginRight: '15px' }}
                >
                  Eject slot
                </Button>
                <TextFieldV2
                  label="Slot number"
                  value={slotNumber}
                  onChange={(e) => {
                    setSlotNumber(e.target.value);
                  }}
                />
              </form>
            )}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="leases">
          <ReferenceManyField label="Leases" reference="lease" target="sourceStationId">
            <Datagrid classes={{ table: classes.table }}>
              <FunctionField
                label="Created At"
                render={(record) => moment(record.createdAt).format('HH:mm DD/MM/YYYY')}
              />
              <TextField source="sourceStationId" />
              <TextField source="returnStationId" />
              <ReferenceField
                allowEmpty
                label="Powerbank"
                source="powerbank.id"
                reference="powerbank"
              >
                <TextField source="hardwareId" />
              </ReferenceField>
              <TextField source="userId" />
              <TextField source="status" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="history">
          <Grid container>
            <Grid item xs={12}>
              <label htmlFor="fromDate" style={{ marginRight: '15px' }}>
                <b>From: </b>
                <input
                  id="fromDate"
                  type="date"
                  onChange={(e) => setFromDate(e.target.value)}
                  value={fromDate}
                />
              </label>
              <label htmlFor="toDate">
                <b>To: </b>
                <input
                  id="toDate"
                  type="date"
                  onChange={(e) => setToDate(e.target.value)}
                  value={toDate}
                />
              </label>
            </Grid>
            <Grid alignContent="left" item md={6} xs={12}>
              <h3>Station Status</h3>
              <StationsHistoryChart fromDate={fromDate} toDate={toDate} stationId={stationId} />
            </Grid>
          </Grid>
          <ReferenceManyField label="History" reference="station-history" target="stationId">
            <Datagrid>
              <TextField source="id" />
              <TextField source="status" sortable={false} />
              <FunctionField
                label="Created At"
                render={(record) => {
                  setStationId(props.id);
                  return moment(record.createdAt).format('HH:mm DD/MM/YYYY');
                }}
              />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
});
