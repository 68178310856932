import { string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { getScanAttempts } from '../../api/statistics';
import { chartJSTimelineOptions } from '../../config/constants';

const ScanAttemptStats = ({ fromDate, toDate }) => {
  const [data, setData] = useState({});

  const fetchData = async () => {
    const then = new Date();
    then.setDate(new Date().getDate() - 2);

    const { data: response } = await getScanAttempts(new Date(fromDate), new Date(toDate));
    setData({
      datasets: [
        {
          label: 'Online Scans',
          fill: false,
          backgroundColor: 'rgba(121,168,0,1)',
          borderColor: 'rgba(121,168,0,1)',
          data: response.online.map((el) => ({ x: new Date(el.createdAt), y: el.value })),
        },
        {
          label: 'Offline Scans',
          fill: false,
          backgroundColor: 'rgba(168,0,110,1)',
          borderColor: 'rgba(168,0,110,1)',
          data: response.offline.map((el) => ({ x: new Date(el.createdAt), y: el.value })),
        },
      ],
    });
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate]);

  return <Line data={data} options={chartJSTimelineOptions} />;
};

ScanAttemptStats.propTypes = {
  fromDate: string,
  toDate: string,
};

export default ScanAttemptStats;
