import { Card } from '@material-ui/core';
import { string } from 'prop-types';
import * as React from 'react';

const Badge = ({ title, value, link }) => {
  return (
    <Card style={{ padding: '10px', textAlign: 'center' }}>
      <a href={link} style={{ textDecoration: 'none', color: 'black' }}>
        <h4>{title}</h4>
        <h2>{value}</h2>
      </a>
    </Card>
  );
};

Badge.propTypes = {
  title: string.isRequired,
  value: string.isRequired,
  link: string,
};

export default Badge;
