import React from 'react';
import {
  List,
  Create,
  Edit,
  SimpleForm,
  Datagrid,
  EditButton,
  required,
  TextInput,
  TextField,
} from 'react-admin';

export const CurrencyList = (props) => (
  <List {...props} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid>
      <TextField
        label="Currency"
        source="name"/>
      <EditButton />
    </Datagrid>
  </List>
);

export const CurrencyCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          label="Currency"
          source="name"
          validate={[required()]}/>
      </SimpleForm>
    </Create>
  );
};

export const CurrencyEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput
        label="Currency"
        source="name"
        validate={[required()]}/>
    </SimpleForm>
  </Edit>
);
