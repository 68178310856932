import React from 'react';
import {
  Create,
  SimpleForm,
  NumberInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  TextInput,
  minLength,
  minValue,
  required,
  BooleanInput,
} from 'react-admin';

const AddBalance = (props) => {
  const optionRenderer = ({ cellphone, email, firstname, lastname }) => {
    if (cellphone) {
      return cellphone;
    }

    if (email) {
      return email;
    }

    if (firstname && lastname) {
      return `${firstname} ${lastname}`;
    }

    return '';
  };

  return (
    <Create {...props}>
      <SimpleForm redirect="create">
        <NumberInput source="amount" validate={[required(), minValue(1)]} />
        <TextInput
          validate={[required(), minLength(1)]}
          source="message"
          defaultValue="Your balance was credited"
        />
        <BooleanInput label="Fill all users" source="fillAllUsers" />
        <ReferenceArrayInput source="users" label="Users" reference="user" allowEmpty perPage={500}>
          <AutocompleteArrayInput optionText={optionRenderer} optionValue="id" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default AddBalance;
