import React from 'react';
import {
  List,
  Create,
  Edit,
  SimpleForm,
  Datagrid,
  EditButton,
  TextInput,
  TextField,
  NumberInput,
  FunctionField,
  CardActions,
  CreateButton,
  RefreshButton,
  SelectInput,
  required,
} from 'react-admin';
import { PriceInput } from '../shared/price-input.component';

const PriceActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </CardActions>
);

export const PriceList = (props) => (
  <List {...props} actions={<PriceActions />} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid>
      <TextField source="name" label="Name" />
      <FunctionField label="Rent rates (mins)" render={(record) => record.rateIncrementInMinutes} />
      <FunctionField label="Rent price" render={(record) => record.rateFee / 100} />
      <FunctionField label="Stolen time (mins)" render={(record) => record.maxDurationInMinutes} />
      <FunctionField label="Stolen price" render={(record) => record.stolenPrice / 100} />
      <FunctionField label="Max Payment" render={(record) => record.maxPayment / 100} />
      <EditButton />
    </Datagrid>
  </List>
);

const PriceForm = (props) => (
  <SimpleForm {...props}>
    <SelectInput
      alwaysOn
      source="country"
      resettable
      choices={[
        { id: 'SE', name: 'SE' },
        { id: 'NO', name: 'NO' },
        { id: 'FI', name: 'FI' },
        { id: 'DK', name: 'DK' },
      ]}
    />
    <SelectInput
      alwaysOn
      source="priceType"
      resettable
      choices={[
        { id: 'WEB_APP', name: 'Web' },
        { id: 'APP', name: 'App' },
      ]}
    />
    <TextInput source="name" label="Name" />
    <NumberInput
      source="rateIncrementInMinutes"
      label="Rate of time at which user is charged (every N minutes)"
    />
    <PriceInput source="reservationFee" label="Reservation Fee" validate={[required()]} />
    <PriceInput
      source="minCardValidationFee"
      label="Minimal card validation fee"
      validate={[required()]}
    />
    <PriceInput source="rateFee" label="Fee for a fraction of usage time" />
    <SelectInput
      alwaysOn
      source="currency"
      resettable
      choices={[
        { id: 'SEK', name: 'SEK' },
        { id: 'NOK', name: 'NOK' },
        { id: 'EUR', name: 'EUR' },
        { id: 'DKK', name: 'DKK' },
      ]}
    />
    <NumberInput
      source="maxDurationInMinutes"
      label="Time when a powerbank is considered stolen (minutes)"
    />
    <PriceInput source="stolenPrice" label="Price for a stolen powerbank" validate={[required()]} />
    <PriceInput source="maxPayment" label="Max payment" validate={[required()]} />
  </SimpleForm>
);

export const PriceCreate = (props) => (
  <Create {...props}>
    <PriceForm {...props} />
  </Create>
);

export const PriceEdit = (props) => (
  <Edit {...props}>
    <PriceForm {...props} />
  </Edit>
);
