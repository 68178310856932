import React from 'react';
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
} from 'react-admin';

const PartnersBalanceFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Partner"
      source="partnerId"
      reference="partner"
      alwaysOn
      perPage={50}
      allowEmpty
    >
      <AutocompleteInput optionText="name" optionValue="id" />
    </ReferenceInput>
  </Filter>
);

const PartnersBalanceList = (props) => {
  const renderMoneyValue = (amount) => parseFloat(amount) / 100;

  return (
    <List
      {...props}
      actions={false}
      filters={<PartnersBalanceFilter />}
      title="Partners balance"
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid>
        <ReferenceField allowEmpty label="Partner" source="partnerId" reference="partner">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" showTime locales="en-GB" />
        <TextField source="type" />
        <TextField source="currency" />
        <FunctionField label="Lease cost" render={(record) => renderMoneyValue(record.leaseCost)} />
        <FunctionField label="Gross" render={(record) => renderMoneyValue(record.grossChange)} />
        <FunctionField label="Net" render={(record) => renderMoneyValue(record.netChange)} />
        <FunctionField label="VAT" render={(record) => renderMoneyValue(record.vatFee)} />
        <FunctionField
          label="Transaction Fee"
          render={(record) => renderMoneyValue(record.transactionFee)}
        />
        <FunctionField
          label="Total Gross"
          render={(record) => renderMoneyValue(record.grossRevenue)}
        />
        <FunctionField label="Total Net" render={(record) => renderMoneyValue(record.netRevenue)} />
        <ReferenceField allowEmpty label="Lease" source="referenceId" reference="lease">
          <TextField source="id" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default PartnersBalanceList;
