import React from 'react';
import PropTypes from 'prop-types';
import countries from '../utils/countries.json';

const CountryTextField = ({ source, record = {} }) => (
  <React.Fragment>
    {source
      ? countries.map((country, key) => {
        if (record[source] === country['alpha-2']) {
          const { name } = country;
          return <span key={country['country-code']}>{name}</span>;
        }
        return null;
      })
      : null}
  </React.Fragment>
);

CountryTextField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.shape({ id: PropTypes.number }),
};

export default CountryTextField;
