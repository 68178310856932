import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  Show,
  ReferenceManyField,
  Datagrid,
  TextField,
  DateField,
  TabbedShowLayout,
  Tab,
  EmailField,
  ReferenceField,
} from 'react-admin';

import { getLeaseTransactions } from '../../../api';

const UserShow = (props) => {
  const [modal, setModal] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const getTransactions = async (id) => {
    const { data = [] } = await getLeaseTransactions(id);
    setTransactions(data);
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
    setTransactions([]);
  };
  return (
    <Fragment>
      <Dialog
        open={modal}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {!!transactions.length && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="right">type</TableCell>
                  <TableCell align="right">status</TableCell>
                  <TableCell align="right">amount</TableCell>
                  <TableCell align="right">currency</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.type}
                    </TableCell>
                    <TableCell align="right">{row.status}</TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                    <TableCell align="right">{row.currency}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {!transactions.length && "Transactions don't exist"}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Show {...props}>
        <TabbedShowLayout>
          <Tab label="User">
            <TextField source="firstname" label="Firstname" allowEmpty />
            <TextField source="lastname" label="Lastname" allowEmpty />
            <TextField source="country" label="Country" allowEmpty />
            <EmailField source="email" label="Email" allowEmpty />
            <TextField source="cellphone" label="Phone" allowEmpty />
            <TextField source="role" label="Role" allowEmpty />
            <DateField source="createdAt" showTime locales="en-GB" label="Created At" />
          </Tab>
          <Tab label="History">
            <ReferenceManyField label="History" reference="lease" target="userId">
              <Datagrid>
                <ReferenceField
                  label="Linked location"
                  source="sourceStation.locationId"
                  reference="location"
                >
                  <TextField source="name" />
                </ReferenceField>
                <TextField source="fee" label="Fee" />
                <TextField source="currency" label="Currency" />
                <DateField source="extractTime" showTime locales="en-GB" label="Extract time" />
                <DateField source="returnTime" showTime locales="en-GB" label="Return time" />
                <TextField source="sourceStation.hardwareId" label="Source Station" />
                <TextField source="returnStation.hardwareId" label="Return Station" />
                <TextField source="powerbank.hardwareId" label="Powerbank" />
                <ModalBtn onClick={getTransactions} />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    </Fragment>
  );
};

const ModalBtn = (props) => {
  const { record } = props;
  const { id } = record;
  const { onClick } = props;
  return (
    <Button color="primary" onClick={(e) => onClick(id)}>
      <EyeIcon style={{ marginRight: '10px' }} />
      Transactions
    </Button>
  );
};

export default UserShow;
