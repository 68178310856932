import React from 'react';
import {
  List,
  Create,
  Edit,
  SimpleForm,
  Datagrid,
  EditButton,
  TextField,
  TextInput,
  required,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

export const CountryList = (props) => (
  <List {...props} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid>
      <TextField
        label="Name"
        source="name"
      />
      <TextField
        label="Code"
        source="code"
      />
      <ReferenceField
        label="Price"
        reference="price"
        source="priceId"
      >
        <TextField
          label="Price"
          source="name"
        />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

export const CountryCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          source="name"
          label="Name"
          validate={[required()]}
        />
        <TextInput
          source="code"
          label="Code"
          validate={[required()]}
        />
        <ReferenceInput
          label="Price"
          reference="price"
          source="priceId"
          validate={[required()]}
        >
          <AutocompleteInput optionText={(record) => record.name} />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export const CountryEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        label="Name"
        validate={[required()]}
      />
      <TextInput
        source="code"
        label="Code"
        validate={[required()]}
      />
      <ReferenceInput
        label="Price"
        reference="price"
        source="priceId"
        validate={[required()]}
      >
        <AutocompleteInput optionText={(record) => record.name} />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
