import React from 'react';
import {
  List,
  Create,
  Edit,
  SimpleForm,
  Datagrid,
  EditButton,
  TextField,
  BooleanInput,
  FunctionField,
  required,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import { PriceInput } from '../shared/price-input.component';

export const TopupTemplateList = (props) => (
  <List {...props} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid>
      <ReferenceField
        label="Currency"
        reference="currency"
        source="currencyId"
        allowEmpty>
        <TextField label="Currency" source="name" />
      </ReferenceField>
      <FunctionField label="Amount" render={(record) => record.amount / 100} />
      <FunctionField label="Bonus" render={(record) => record.bonus / 100} />
      <FunctionField label="Is active" render={(record) => (record.isActive ? 'ON' : 'OFF')} />
      <EditButton />
    </Datagrid>
  </List>
);

export const TopupTemplateCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <BooleanInput
          source="isActive"
          label="Is active"
        />
        <PriceInput
          source="amount"
          label="Amount"
          validate={[required()]}
        />
        <PriceInput
          source="bonus"
          label="Bonus"
          validate={[required()]}
        />
        <ReferenceInput
          label="Currency"
          reference="currency"
          source="currencyId"
        >
          <AutocompleteInput optionText={(record) => record.name} />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export const TopupTemplateEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput
        source="isActive"
        label="Is active"
      />
      <PriceInput
        source="amount"
        label="Amount"
        validate={[required()]}
      />
      <PriceInput
        source="bonus"
        label="Bonus"
        validate={[required()]}
      />
      <ReferenceInput
        label="Currency"
        reference="currency"
        source="currencyId"
      >
        <AutocompleteInput optionText={(record) => record.name} />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
