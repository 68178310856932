import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EmailField,
  ShowButton,
  Filter,
  TextInput,
  EditButton,
  SelectInput,
} from 'react-admin';

const UserFilters = (props) => {
  return (
    <Filter {...props}>
      <TextInput alwaysOn source="email" label="Email" />
      <TextInput alwaysOn source="cellphone" label="Phone" />
      <SelectInput
        alwaysOn
        resettable
        label="Role"
        source="role"
        choices={[
          {
            id: 'ADMIN',
            name: 'ADMIN',
          },
          {
            id: 'USER',
            name: 'USER',
          },
        ]}
      />
    </Filter>
  );
};

const UserList = (props) => (
  <List {...props} filters={<UserFilters />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="firstname" />
      <TextField source="lastname" />
      <TextField source="cellphone" />
      <TextField source="country" />
      <TextField source="role" />
      <EmailField source="email" />
      <DateField
        source="createdAt"
        options={{
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }}
      />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default UserList;
