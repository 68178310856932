import React from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
  ReferenceField,
  Filter,
  SelectInput,
  Pagination,
  TextInput,
} from 'react-admin';
import { AppTypes, EventTypes } from '../../config/constants';
import { renderFieldUserInfo } from '../../utils/users';

const Filters = (props) => {
  return (
    <Filter {...props}>
      <SelectInput
        source="event"
        label="Event Type"
        alwaysOn
        resettable
        choices={EventTypes.map((event) => ({ id: event, name: event }))}
      />

      <SelectInput
        source="appType"
        label="App Type"
        alwaysOn
        resettable
        choices={AppTypes.map((event) => ({ id: event, name: event }))}
      />
      <TextInput alwaysOn label="Country" source="country" />
    </Filter>
  );
};

const LeasesEventsList = (props) => {
  return (
    <List
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
      actions={false}
      filters={<Filters />}
      pagination={<Pagination {...props} rowsPerPageOptions={[50, 100]} />}
      perPage={50}
      title="Lease Events"
    >
      <Datagrid>
        <TextField source="event" label="Event" />
        <DateField source="createdAt" showTime locales="en-GB" />
        <FunctionField label="User" allowEmpty render={renderFieldUserInfo} />
        <TextField source="userId" label="User #" />
        <TextField source="appType" label="App" />

        <ReferenceField allowEmpty label="Station" source="stationId" reference="station">
          <TextField source="hardwareId" />
        </ReferenceField>

        <ReferenceField
          allowEmpty
          label="Location"
          source="station.location.id"
          reference="location"
        >
          <TextField source="internalName" />
        </ReferenceField>

        <ReferenceField allowEmpty label="Powerbank" source="powerbankId" reference="powerbank">
          <TextField source="hardwareId" />
        </ReferenceField>
        <TextField source="leaseId" label="Lease #" />
      </Datagrid>
    </List>
  );
};

export default LeasesEventsList;
