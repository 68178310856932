import React from 'react';
import {
  Edit,
  SimpleForm,
  required,
  SelectInput,
} from 'react-admin';

const UserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <SelectInput
        label="Role"
        source="role"
        choices={[
          {
            id: 'ADMIN',
            name: 'ADMIN',
          },
          {
            id: 'USER',
            name: 'USER',
          },
        ]}
        validate={required()}
      />
    </SimpleForm>
  </Edit>
);

export default UserEdit;
